import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ReplyIcon from '@mui/icons-material/Reply';
import { styled } from '@mui/material/styles';

const BackBtn = styled(Button)({
  textTransform: 'none',
  borderRadius: '50px',
  border: '1px solid #33bfff',
  color: '#33bfff'
});

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div style={{
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      display: 'flex',
      alignItems: 'center',
    }}>
      <div style={{
          padding: '50px 0 60px',
          border: '1px dashed #CCCCCC',
          width: '60%',
          background: '#F9F9F9',
          borderRadius: '10px',
          margin: '0 auto'
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            filter: 'grayscale(1)',
            opacity: 0.25
          }}>
              <img style={{
                height: '100px',
                width: '100px',
              }} src='https://iwrmdataportal.unepdhi.org/static/media/clean-water-and-sanitation.9729f2ea138cf1e5ccb9a566e90f2993.svg' alt=''  />
              <img style={{
                height: '170px',
                width: '170px',
              }} src='https://iwrmdataportal.unepdhi.org/static/media/un-environment.8672d8916119c4e56f0e8d3631870ede.svg' alt=''  />
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            fontSize: '18px', 
            color: '#ababab',
            fontWeight: 400
          }}>
              <h3 style={{color: '#666666'}}>404 - Page Not Found</h3>
              <BackBtn
                component="label"
                role={undefined}
                variant="outlined"
                size='small'
                startIcon={<ReplyIcon />}
                onClick={() => {
                  navigate('/')
                }}
              >
                Go to home
              </BackBtn>
          </div>
      </div>
    </div>
  )
  
}

export default NotFound