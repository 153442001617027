import React from 'react';
import { Container } from   '@mui/system';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';

const NewsStep3 = ({newsObject}) => {

    return (
        <Container 
            style={{
                position: 'relative',
                width: '100%',
                height: '350px',
                padding: '20px 0',
                backgroundColor: 'whitesmoke',
                borderRadius: '8px',
                border: '1px dashed #CCCCCC',
                display: 'flex',
                alignItems: 'center',
                overflowY: 'auto'
            }}
        >
            <Box
                component="img"
                sx={{
                  height: 250,
                  display: 'block',
                  width: '45%',
                }}
                src={newsObject.img}
                alt={newsObject.name}
            />
            <Box 
                style={{
                    width: '55%',
                    height: 250,
                    backgroundColor: 'rgb(219, 228, 233)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
            >
                <Typography 
                    style={{
                        userSelect: 'none',
                        color: 'rgb(9, 51, 75)',
                        fontWeight: 700,
                        fontSize: '14px',
                        lineHeight: '30px',
                        margin: '0 20px'
                    }}
                >
                    {newsObject.name}
                </Typography>
                <Typography
                    style={{
                        fontWeight: 400,
                        fontSize: '10px',
                        color: 'rgb(9, 51, 75)', margin: ' 0 20px 10px'
                    }}
                >
                    {newsObject.content}
                </Typography>
                <Box style={{
                    display: 'flex',
                    paddingLeft: '20px'
                }}>
                {
                    newsObject.button.map((button, index) => (
                        <Button
                            key={index}
                            variant="contained"
                            color='primary'
                            style={{
                                color: 'white',
                                width: 'fit-content',
                                marginRight: '10px',
                                textTransform: 'none',
                                fontWeight: 200,
                                fontSize: '10px'
                            }} 
                            size='small'
                        >{button.buttonText}</Button>
                    ))
                }
                </Box>
            </Box>
            <Box style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}></Box>
        </Container>
    )
}

export default NewsStep3