import React from 'react';
import TextField from '@mui/material/TextField';
import { Container } from '@mui/system';
import Grid from '@mui/material/Grid';
import Editor from '../../components/editor/Editor';

const AboutTabsStep1 = ({object, setObject}) => {
    const handleContentChange = (value) => {
        setObject({ ...object, content: value })
    };
    return (
        <Container 
            style={{
                width: '100%',
                height: '550px',
                padding: '20px',
                backgroundColor: 'whitesmoke',
                borderRadius: '8px',
                border: '1px dashed #CCCCCC',
                overflowY: 'auto'
            }}
        >
            <Grid container spacing={2} className='jodit410'>
                <Grid item xs={12} textAlign="right">
                    <TextField
                        id="tabName"
                        fullWidth
                        label="Tab Name"
                        type='text'
                        size='small'
                        style={{marginBottom: '15px', backgroundColor: 'white'}}
                        value={object.name}
                        onChange={(e) => setObject({...object, name: e.target.value})}
                    />
                    <Editor object={object} onContentChange={(value) => handleContentChange(value)} />
                </Grid>
            </Grid>
        </Container>
    )
}

export default AboutTabsStep1