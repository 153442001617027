import apiClient from './apiClient';

export const loadContent = async () => {
  try {
      const response = await apiClient.get('/About/getAllAboutContent');
      return response.data;
  } catch (error) {
      console.error('Error creating news:', error);
      throw error;
  }
};

export const loadContentId = async (id) => {
  try {
      const response = await apiClient.get('/About/getAboutContentDetailById/'+id);
      return response.data;
  } catch (error) {
      console.error('Error creating news:', error);
      throw error;
  }
};

export const createContent = async (content, file) => {
  const formData = new FormData();

  if (file) {
    formData.append('img', file);
  }

  formData.append('name', content.name);
  formData.append('content', content.content);
  formData.append('isActive', content.isActive);

  try {
      const response = await apiClient.post('/About/postAboutContent', formData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const editContent = async (content, file) => {
  const id = content.id
  const formData = new FormData();

  if (file) {
    formData.append('img', file);
  }

  formData.append('name', content.name);
  formData.append('content', content.content);
  formData.append('isActive', content.isActive);

  try {
      const response = await apiClient.put('/About/updateAboutContentById/'+id, formData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const deleteContent = async (id) => {
  try {
    const response = await apiClient.delete('/About/deleteAboutContentById/'+id);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};

export const updateStatus = async (status) => {
  try {
    const response = await apiClient.put('/About/updateAboutContentActiveStatus', status);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};

export const reorderContent = async (newOrder) => {
  try {
    const response = await apiClient.put('/About/updateAboutContentOrders', newOrder);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};