import apiClient from './apiClient';

export const loadCover = async () => {
    try {
        const response = await apiClient.get('/SupportProgramme/getSupportProgrammeMediaImageLink');
        return response.data;
    } catch (error) {
        console.error('Error creating news:', error);
        throw error;
    }
};

export const updateCover = async (file) => {
  const formData = new FormData();
  formData.append('img', file);
  try {
      const response = await apiClient.post('/SupportProgramme/postSupportProgrammeMediaImage', formData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const loadContent = async () => {
  try {
      const response = await apiClient.get('/SupportProgramme/getAllSupportProgrammeContent');
      return response.data;
  } catch (error) {
      console.error('Error creating news:', error);
      throw error;
  }
};

export const loadContentId = async (id) => {
  try {
      const response = await apiClient.get('/SupportProgramme/getSupportProgrammeContentById/'+id);
      return response.data;
  } catch (error) {
      console.error('Error creating news:', error);
      throw error;
  }
};

export const uploadContent = async (content) => {
  const formData = new FormData();

  formData.append('name', content.name);
  formData.append('content', content.content);
  formData.append('isActive', content.isActive);

  try {
      const response = await apiClient.post('/SupportProgramme/postSupportProgrammeContent', formData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const editContent = async (content) => {
  const id = content.id
  const formData = new FormData();

  formData.append('name', content.name);
  formData.append('content', content.content);
  formData.append('isActive', content.isActive);

  try {
      const response = await apiClient.put('/SupportProgramme/updateSupportProgrammeContentById/'+id, formData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const deleteContent = async (id) => {
  try {
    const response = await apiClient.delete('/SupportProgramme/deleteSupportProgrammeContentById/'+id);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};

export const updateStatus = async (status) => {
  try {
    const response = await apiClient.put('/SupportProgramme/updateSupportProgrammeContentActiveStatus', status);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};

export const reorderContent = async (newOrder) => {
  try {
    const response = await apiClient.put('/SupportProgramme/updateSupportProgrammeContentOrders', newOrder);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};