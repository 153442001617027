import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Container } from '@mui/system';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { getNavigation } from '../../api/globalCMSServices';

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #F0f0f0',
  backgroundColor: '#FFFFFF',
  '& .MuiTabs-indicator': {
    backgroundColor: '#33bfff',
  },
});

const AntTab = styled(Tab)({
  textTransform: 'none'
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#33bfff',
    },
  },
});

const CMSHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(null);
  const [page, setPage] = useState([])

  const fetchMenu = async () => {
    try {
      const content = await getNavigation();
      setPage(content);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchMenu();
  }, []);
  

  useEffect(() => {
    const activeNavItems = page.filter(navItem => navItem.isActive);
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const lastTwoSegments = pathSegments.slice(-2).join('/');
    const matchingIndex = activeNavItems.findIndex(navItem => navItem.link.endsWith(lastTwoSegments));
    setValue(matchingIndex !== -1 ? matchingIndex : null);
  }, [location.pathname, page]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} style={{width: '100%', backgroundColor: '#FCFCFC'}} className='CMSMobileMenu'>
        <Box>
          <AntTabs 
            value={value !== null ? value : false}
            onChange={handleChange}
            centered
            allowScrollButtonsMobile
          >
            {
              page
              .filter(nav => nav.isActive)
              .map(nav => (
                <AntTab 
                  key={nav.key}
                  label={nav.label}
                  onClick={() => {
                    navigate(nav.link)
                  }} />
              ))
            }
          </AntTabs>
        </Box>
      </Container> 
    </ThemeProvider>
  )
}

export default CMSHeader