import apiClient from './apiClient';

export const loadReportType = async () => {
  try {
      const response = await apiClient.get('/ResourceLibraryReportType/getAllReportTypeContent');
      return response.data;
  } catch (error) {
      console.error('Error creating news:', error);
      throw error;
  }
};

export const loadReportTypeId = async (id) => {
  try {
      const response = await apiClient.get('/ResourceLibraryReportType/getReportTypeContentDetailById/'+id);
      return response.data;
  } catch (error) {
      console.error('Error creating news:', error);
      throw error;
  }
};

export const createReportType = async (content) => {
  const formData = new FormData();

  formData.append('typeName', content.typeName);
  formData.append('fileType', content.fileType);
  formData.append('GA_Category', 'resource_library');
  formData.append('GA_Action', content.GA_Action);
  
  try {
      const response = await apiClient.post('/ResourceLibraryReportType/postReportType', formData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const editReportType = async (content) => {
  const id = content.id
  const formData = new FormData();

  formData.append('typeName', content.typeName);
  formData.append('fileType', content.fileType);
  formData.append('GA_Category', 'resource_library');
  formData.append('GA_Action', content.GA_Action);

  try {
      const response = await apiClient.put('/ResourceLibraryReportType/updateReportTypeContentById/'+id, formData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const deleteReportType = async (id) => {
  try {
    const response = await apiClient.delete('/ResourceLibraryReportType/deleteReportTypeContentById/'+id);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};

export const updateReportTypeStatus = async (status) => {
  try {
    const response = await apiClient.put('/ResourceLibraryReportType/updateReportTypeContentActiveStatus', status);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};

export const reorderReportType = async (newOrder) => {
  try {
    const response = await apiClient.put('/ResourceLibraryReportType/updateReportTypeContentOrders', newOrder);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};