import apiClient from './apiClient';

export const loadNewsId = async (id) => {
    try {
      const response = await apiClient.get('/HomeSliders/'+id);
      return response.data;
    } catch (error) {
      console.error('Error creating news:', error);
      throw error;
    }
};

export const uploadNews = async (newsObject, file) => {
    const formData = new FormData();

    if (file) {
        formData.append('img', file);
    }

    formData.append('name', newsObject.name);
    formData.append('content', newsObject.content);
    formData.append('isActive', newsObject.isActive);
    formData.append('button', JSON.stringify(newsObject.button));

    try {
        const response = await apiClient.post('/HomeSliders', formData);
        return response.data;
    } catch (error) {
        console.error('Error uploading:', error);
        throw error;
    } 
};

export const editNews = async (newsObject, file) => {
    const id = newsObject.id
    const formData = new FormData();
    if (file) {
        formData.append('img', file);
    }

    formData.append('name', newsObject.name);
    formData.append('content', newsObject.content);
    formData.append('isActive', newsObject.isActive);
    formData.append('button', JSON.stringify(newsObject.button));

    try {
        const response = await apiClient.put('/HomeSliders/'+id, formData);
        return response.data;
    } catch (error) {
        console.error('Error uploading:', error);
        throw error;
    } 
};

export const loadNews = async () => {
    try {
        const response = await apiClient.get('/HomeSliders');
        return response.data;
    } catch (error) {
        console.error('Error creating news:', error);
        throw error;
    }
};

export const deleteNewsId = async (id) => {
    try {
      const response = await apiClient.delete('/HomeSliders/'+id);
      return response.data;
    } catch (error) {
      console.error('Error creating news:', error);
      throw error;
    }
};

export const bannerStatus = async (status) => {
    try {
      const response = await apiClient.put('/HomeSliders/ActiveStatus', status);
      return response.data;
    } catch (error) {
      console.error('Error creating news:', error);
      throw error;
    }
};

export const reorderBanner = async (newOrder) => {
    try {
      const response = await apiClient.put('/HomeSliders/ContentOrders', newOrder);
      return response.data;
    } catch (error) {
      console.error('Error creating news:', error);
      throw error;
    }
};
