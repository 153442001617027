import React, { useEffect } from 'react'
import {
  Routes,
  Route,
  useLocation,
  Navigate
} from "react-router-dom";
import Breadcrumb from './components/breadcrumb/Breadcrumb';
import Home from './pages/home/Home'
import Map from './pages/map/Map'
import Country from './pages/country/Country'
import Publication from './pages/publication/Publication'
import Support from './pages/support/Support'
import About from './pages/about/About'
import Login from './cms/Login' 
import Header from './components/header/Header';
import CMSHeader from './components/header/CMSHeader';
import CMSMenu from './components/menu/CMSMenu';
import CMSHome from './cms/CMSHome';
import CMSAbout from './cms/CMSAbout';
import CMSCountryReports from './cms/CMSCountryReports';
import CMSDataCollection from './cms/CMSDataCollection';
import CMSInteractiveMap from './cms/CMSInteractiveMap';
import CMSResourceLibrary from './cms/CMSResourceLibrary';
import CMSResourceLibrarySubPageContainer from './cms/CMSResourceLibrarySubPageContainer';
import CMSSupportProgramme from './cms/CMSSupportProgramme';
import CMSChangePassword from './cms/CMSChangePassword';
import ResetPassword from './cms/ResetPassword';
import ForgotPassword from './cms/ForgotPassword';
import CMSFAQ from './cms/CMSFAQ';
import NotFound from './components/notfound/404';
import Footer from './components/footer/Footer';
import TermOfServices from './pages/termOfServices/TermOfServices';
import ReactGA from 'react-ga';
import 'mapbox-gl/dist/mapbox-gl.css';
import Spinner from './components/spinner/Spinner';
import { useAppContext } from './context/AppContext';
import DataCollection from './pages/dataCollection/DataCollection';

const App = () => {

  const {
    state: { loading }
  } = useAppContext()
  const location = useLocation()

  const trackingId = 'UA-66550944-1';
  ReactGA.initialize(trackingId);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location])

  return (
    <div className="app">
      {!(location.pathname.includes('/cms') || location.pathname === '/login' || location.pathname === '/forgot-password' || location.pathname === '/change-password'  || location.pathname === '/404') && <Header />}
      {(location.pathname.includes('/cms')) && <CMSHeader />}
      {(location.pathname.includes('/cms')) && <CMSMenu />}
      <div className={!(location.pathname.includes('/cms')) ? 'app-container' : 'app-container cms'}>
        {!(location.pathname === '/' || location.pathname === '/interactive-map' || location.pathname.includes('/cms') || location.pathname === '/login' || location.pathname === '/forgot-password' || location.pathname === '/change-password' || location.pathname === '/404') && <Breadcrumb name={location.pathname} />}
        <Routes>
          <Route match="exact" path="/" element={<Home />} />
          <Route match="partial" path="/data-collection" element={<DataCollection />} />
          <Route match="partial" path="/interactive-map" element={<Map />} />
          <Route match="partial" path="/country-reports" element={<Country />} />
          <Route match="partial" path="/publications/:id" element={<Publication />} />
          <Route match="partial" path="/support-programme" element={<Support />} />
          <Route match="partial" path="/about" element={<About />} />
          <Route match="partial" path="/term-of-service" element={<TermOfServices />} />
          <Route match="partial" path="/login" element={<Login />} />
          <Route match="partial" path="/forgot-password" element={<ForgotPassword />} />
          <Route match="partial" path="/change-password" element={<ResetPassword />} />
          <Route match="partial" path="/cms/home" element={<CMSHome />} />
          <Route match="partial" path="/cms/about" element={<CMSAbout />} />
          <Route match="partial" path="/cms/about/FAQ" element={<CMSFAQ />} />
          <Route match="partial" path="/cms/country-reports" element={<CMSCountryReports />} />
          <Route match="partial" path="/cms/data-collection" element={<CMSDataCollection />} />
          <Route match="partial" path="/cms/interactive-map" element={<CMSInteractiveMap />} />
          <Route match="partial" path="/cms/resource-library" element={<CMSResourceLibrary />} />
          <Route match="partial" path="/cms/resource-library/:id" element={<CMSResourceLibrarySubPageContainer />} />
          <Route match="partial" path="/cms/support-programme" element={<CMSSupportProgramme />} />
          <Route match="partial" path="/cms/change-password" element={<CMSChangePassword />} />
          <Route match="partial" path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
          <Route path="/publications/*" element={<Navigate to="/404" />} />
        </Routes>
      </div>
      {!(location.pathname === '/interactive-map' || location.pathname === '/forgot-password' || location.pathname === '/change-password'  || location.pathname.includes('/cms') || location.pathname === '/login' || location.pathname === '/404') && <Footer />}
      {
        loading && <Spinner />
      }
    </div>
  )
}

export default App