import { Container } from '@mui/system';

const CMSDataCollection = () => {
  return (
    <Container style={{height: 'calc(100% - 20px)'}}>
      <h1>Data Collection</h1>
    </Container>
  )
}

export default CMSDataCollection