import apiClient from './apiClient';

export const loadSubPage = async () => {
  try {
      const response = await apiClient.get('/ResourceLibrarySubPage/getAllSubPageContent');
      return response.data;
  } catch (error) {
      console.error('Error creating news:', error);
      throw error;
  }
};

export const loadSubPageId = async (id) => {
  try {
      const response = await apiClient.get('/ResourceLibrarySubPage/getSubPageContentDetailById/'+id);
      return response.data;
  } catch (error) {
      console.error('Error creating news:', error);
      throw error;
  }
};

export const createSubPage = async (content) => {
  const formData = new FormData();

  formData.append('name', content.name);
  formData.append('GA_Action', content.GA_Action);
  formData.append('GA_Category', content.GA_Category);
  formData.append('GA_Label', content.GA_Label);
  
  try {
      const response = await apiClient.post('/ResourceLibrarySubPage/postSubPage', formData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const editSubPage = async (content) => {
  const id = content.id
  const formData = new FormData();

  formData.append('name', content.name);
  formData.append('GA_Action', content.GA_Action);
  formData.append('GA_Category', content.GA_Category);
  formData.append('GA_Label', content.GA_Label);

  try {
      const response = await apiClient.put('/ResourceLibrarySubPage/updateSubPageContentById/'+id, formData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const updateSubPageStatus = async (status) => {
  try {
    const response = await apiClient.put('/ResourceLibrarySubPage/updateSubPageContentActiveStatus', status);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};

export const reorderSubPage = async (newOrder) => {
  try {
    const response = await apiClient.put('/ResourceLibrarySubPage/updateSubPageContentOrders', newOrder);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};