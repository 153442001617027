import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Container } from '@mui/system';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ReplyIcon from '@mui/icons-material/Reply';
import Button from '@mui/material/Button';
import { Typography, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { requestResetPassword } from '../api/globalCMSServices';

import Loading from '../components/spinner/Loading';

const BackBtn = styled(Button)({
  textTransform: 'none',
  borderRadius: '50px',
  border: '1px solid #33bfff',
  color: '#33bfff'
});

const style = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-Repeat',
  backgroundSize: 'cover'
}

const ForgotPassword = () => {
  const navigate =  useNavigate();
  const [successResponse, setSuccessResponse] = useState(null)
  const [errorResponse, setErrorResponse] = useState(null)
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState({
    'email': '',
  });

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorResponse(null);
    setSuccessResponse(null);
    try {
      await  requestResetPassword(email);
      setSuccessResponse('We have send you a link to reset your password, please check your email !');
    } catch (error) {
      if( error.response.status === 400 ){
        setErrorResponse('Invalid email format')
      } else if (error.response.status === 404 ) {
        setErrorResponse('This email not registered in our database !')
      }
      
    }
    setLoading(false);
  };

  return (
    <Container style={style} maxWidth={false}>
      <Stack 
        style={{height: '100%'}}
        spacing={{ xs: 1, sm: 2 }}
        direction="column"
        useFlexGap
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        <img style={{height: '170px', width: '170px', filter: 'grayscale(1)'}} src='https://iwrmdataportal.unepdhi.org/static/media/un-environment.8672d8916119c4e56f0e8d3631870ede.svg' alt=''  />
        
        <Typography variant="h1" style={{ fontSize: '24px'}}>Reset Password</Typography>
        <Card sx={{ minWidth: '800px', background: 'rgba(255, 255, 255, 0.60)', backdropFilter: 'blur(7px)', padding: '30px 30px 50px' }}>
          <form onSubmit={handleResetPassword}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '10px 0 20px' }}>
              <MailOutlineIcon sx={{ color: 'action.active', mr: 2, my: 0.5, fontSize: '40px' }} />
              <TextField
                id="userEmail"
                fullWidth
                label="Please input your email"
                type='text'
                variant="outlined"
                required
                disabled={successResponse !== null}
                onChange={(e) => setEmail({...email, email: e.target.value})}/>
            </Box>
            <Button type="submit" variant="contained" disabled={successResponse !== null} fullWidth size="large" style={{margin: '20px 0'}}>Submit</Button>
          </form>
        </Card>
        {successResponse ? <Alert variant="filled" severity="success">{successResponse}</Alert> : ''}
        {errorResponse ? <Alert variant="filled" severity="error">{errorResponse}</Alert> : ''}
        <BackBtn
          component="label"
          role={undefined}
          variant="outlined"
          size='small'
          startIcon={<ReplyIcon />}
          onClick={() => {
            navigate('/login')
          }}
        >
          Back to login
        </BackBtn>
      </Stack>
      {loading ? <Loading loading={loading} setLoading={setLoading} /> : ''}
    </Container>
  )
}

export default ForgotPassword