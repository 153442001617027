import apiClient from './apiClient';

export const loadFAQ = async () => {
  try {
      const response = await apiClient.get('/AboutFAQ/getAllAboutFAQContent');
      return response.data;
  } catch (error) {
      console.error('Error creating news:', error);
      throw error;
  }
};

export const loadContentId = async (id) => {
  try {
      const response = await apiClient.get('/AboutFAQ/getAboutFAQContentDetailById/'+id);
      return response.data;
  } catch (error) {
      console.error('Error creating news:', error);
      throw error;
  }
};

export const createContent = async (content) => {
  const formData = new FormData();

  formData.append('name', content.name);
  formData.append('content', content.content);
  formData.append('isActive', content.isActive);

  try {
      const response = await apiClient.post('/AboutFAQ/postAboutFAQContent', formData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const editContent = async (content) => {
  const id = content.id
  const formData = new FormData();

  formData.append('name', content.name);
  formData.append('content', content.content);
  formData.append('isActive', content.isActive);

  try {
      const response = await apiClient.put('/AboutFAQ/updateAboutFAQContentById/'+id, formData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const deleteContent = async (id) => {
  try {
    const response = await apiClient.delete('/AboutFAQ/deleteAboutFAQContentById/'+id);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};

export const updateStatus = async (status) => {
  try {
    const response = await apiClient.put('/AboutFAQ/updateAboutFAQContentActiveStatus', status);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};

export const reorderContent = async (newOrder) => {
  try {
    const response = await apiClient.put('/AboutFAQ/updateAboutFAQContentOrders', newOrder);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};