import { Container } from '@mui/system';

const CMSInteractiveMap = () => {
  return (
    <Container style={{height: 'calc(100% - 20px)'}}>
      <h1>Interactive Map</h1>
    </Container>
  )
}

export default CMSInteractiveMap