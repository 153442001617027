import React from 'react';
import { Container } from   '@mui/system';
import { Typography, Stack, Link, Box } from '@mui/material';

const RLStep3 = ({object}) => {
    const languageOrder = ["English", "French", "Spanish", "Arabic", "Chinese", "Portuguese", "Russian"];
    const predefinedOrder = [
        "Full report",
        "Visual summary",
        "Executive summary",
        "Social media card",
        "PowerPoint slide",
        "Watch the video"
    ];
    const reports = object.listPublicationsSubReportsParameters;

    const sortByLanguage = (a, b) => {
        const indexA = languageOrder.indexOf(a.language);
        const indexB = languageOrder.indexOf(b.language);
        const adjustedIndexA = indexA !== -1 ? indexA : Infinity;
        const adjustedIndexB = indexB !== -1 ? indexB : Infinity;
        return adjustedIndexA - adjustedIndexB;
    };
    
    const uniqueTypes = Array.from(new Set(reports.map(report => report.typeName)));
    const orderedTypes = [
        ...predefinedOrder,
        ...uniqueTypes.filter(type => !predefinedOrder.includes(type))
    ];
    return (
        <Container 
            style={{
                position: 'relative',
                width: '100%',
                height: '350px',
                padding: '20px 0',
                backgroundColor: 'whitesmoke',
                borderRadius: '8px',
                border: '1px dashed #CCCCCC',
                display: 'flex',
                alignItems: 'center',
                overflowY: 'auto'
            }}
        >
            <Box sx={{mr: 'auto'}}></Box>
            <Box
                component="img"
                sx={{
                  height: 300,
                  display: 'block',
                  maxWidth: '230px',
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                }}
                src={object.image}
                alt={object.name}
            />
            <Box 
                style={{
                    maxWidth: '330px',
                    minHeight: 300,
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                }}
            >
                <Typography 
                    style={{
                        userSelect: 'none',
                        color: 'rgb(9, 51, 75)',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '20px',
                        margin: '10px 10px 0'
                    }}
                >
                    {object.name}
                </Typography>
                <Box
                    style={{
                        display: 'flex',
                        margin: '10px 10px'
                    }}
                >
                    <Typography
                        style={{
                            userSelect: 'none',
                            color: 'rgb(9, 51, 75)',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '18px',
                            width: '45%'
                        }}
                    >
                        <strong>Year:</strong> {object.year}
                    </Typography>
                    <Typography
                        style={{
                            userSelect: 'none',
                            color: 'rgb(9, 51, 75)',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '18px',
                        }}
                    >
                        <strong>Author:</strong> {object.author}
                    </Typography>
                </Box>
                <Typography
                    style={{
                        userSelect: 'none',
                        color: 'rgb(9, 51, 75)',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '18px',
                        margin: '0 10px'
                    }}
                >
                    <strong>Download:</strong>
                </Typography>
                {orderedTypes.map(type => {
                    const filteredReports = reports
                        .filter(report => report.typeName === type)
                        .sort(sortByLanguage);
                    if (filteredReports.length === 0) return null;

                    return (
                        <Stack
                            flexDirection="row"
                            flexWrap="wrap"
                            style={{ marginLeft: "10px" }}
                            key={type}
                        >
                            <Typography sx={{ fontSize: 14, color: "black", marginRight: 1 }}>
                                {type} :
                            </Typography>
                            {filteredReports.map(item => (
                                <Link
                                    href={item.link || item.linkText}
                                    target="_blank"
                                    key={item.subId}
                                    sx={{
                                        textDecoration: "none",
                                        color: "#00aeef",
                                        fontSize: 14,
                                        marginRight: 1,
                                        width: "fit-content"
                                    }}
                                >
                                    {item.label}
                                </Link>
                            ))}
                        </Stack>
                    );
                })}
            </Box>
            <Box sx={{ml: 'auto'}}></Box>
            <Box style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}></Box>
        </Container>
    )
}

export default RLStep3