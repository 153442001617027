import React from 'react';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/system';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });



const RLStep1 = ({object, setObject, setFile}) => {
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setObject({...object, image: reader.result});
                setFile(file)
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Container 
            style={{
                width: '100%',
                height: '400px',
                padding: '20px',
                backgroundColor: 'whitesmoke',
                borderRadius: '8px',
                border: '1px dashed #CCCCCC',
                overflowY: 'auto'
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Box 
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            border: '1px solid #cccccc',
                            padding: '15px',
                            borderRadius: '4px',
                            backgroundColor: 'white'
                        }}
                    >
                        <Box style={{marginBottom: '10px', textAlign: 'center'}}>
                            {object.image ? (
                                <img
                                    src={object.image}
                                    alt="Selected"
                                    style={{
                                        width: '100%',
                                        height: '280px',
                                    }}
                                />
                            ) : (
                                <Box
                                    style={{
                                        border: '1px dashed #cccccc',
                                        width: '100%',
                                        height: '280px',
                                        borderRadius: '2px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <PhotoSizeSelectActualIcon style={{color: '#cccccc', fontSize: '50px'}} />
                                </Box>
                            )}
                        </Box>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            size='small'
                            startIcon={<PhotoSizeSelectActualIcon />}
                            style={{color: 'white'}}
                        >
                            Browse
                            <VisuallyHiddenInput type="file" accept="image/*" onChange={handleImageChange} />
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} textAlign="right">
                    <TextField
                        id="newsDesc"
                        fullWidth
                        label="Title"
                        type='text'
                        size='small'
                        multiline
                        rows={7}
                        style={{marginBottom: '15px', backgroundColor: 'white'}}
                        value={object.name}
                        onChange={(e) => setObject({...object, name: e.target.value})}
                    />
                    <TextField
                        id="newsTitle"
                        fullWidth
                        label="Year"
                        type='text'
                        size='small'
                        style={{marginBottom: '15px', backgroundColor: 'white', width: 'calc(50% - 5px)'}}
                        value={object.year}
                        onChange={(e) => setObject({...object, year: e.target.value})}
                    />
                    <TextField
                        id="newsTitle"
                        fullWidth
                        label="Author"
                        type='text'
                        size='small'
                        style={{marginBottom: '15px', backgroundColor: 'white', width: 'calc(50% - 5px)', marginLeft: '10px'}}
                        value={object.author}
                        onChange={(e) => setObject({...object, author: e.target.value})}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

export default RLStep1