import React, { useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

const Editor = ({ object, onContentChange }) => {
  const editorRef = useRef(null);

  const config = useMemo(() => {
    const options = [
      'undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'ul', 'ol', '|', 'font', 'fontsize', '|',
      'outdent', 'indent', 'align', '|', 'table', 'link', 'image'
    ];

    return {
      readonly: false,
      placeholder: '',
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1.5,
      enter: 'div',
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      toolbarAdaptive: false,
      resizable: false,
      style: {
        textAlign: 'left',
        font: '12px Roboto',
      },
    };
  }, []);

  return (
    <JoditEditor
      ref={editorRef}
      value={object.content || ''}
      config={config}
      onChange={(value) => onContentChange(value)}
    />
  );
};

export default Editor;
