import React from 'react';
import { useParams } from 'react-router-dom';
import ReportType from './CMSReportType';
import SubPageItem from './CMSSubPageItem';
// import PageNotFound from '../components/notfound/404';

const pageComponents = {
  'report-type': ReportType,
};

const CMSResourceLibrarySubPageContainer = () => {
  const { id } = useParams();
  const PageComponent = pageComponents[id] || SubPageItem;

  return <PageComponent />;
};

export default CMSResourceLibrarySubPageContainer;