import React, { useState } from 'react';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { Container } from '@mui/system';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import KeyIcon from '@mui/icons-material/Key';
import Loading from '../components/spinner/Loading';
import { Box, FormControl, Input, InputLabel, InputAdornment, IconButton, Button, Alert, Typography } from '@mui/material';
import { changePassword } from '../api/globalCMSServices';

const initialValue = {
    userId: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
}

const ChangePassword = () => {
  const navigate = useNavigate();
  const token = Cookies.get('authToken');
  const decodedToken = jwtDecode(token);
  const userId = decodedToken.uid;
  const [showPassword, setShowPassword] = useState(false);
  const [alert, setAlert] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false)
  const [submitValue, setSubmitValue] = useState(initialValue);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitChangePassword = async (e) => {
    setLoading(true);
    setSuccess(null);
    setAlert(null);
    e.preventDefault();
    try {
      await changePassword(submitValue, userId);
      setSubmitValue(initialValue);
      setSuccess('Your password has been successfully updated. Please use your new password for future login sessions.')
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      setAlert(error.response.data.title);
    }
    setLoading(false);
  };

  return (
      <>
        <Container 
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Box
            style={{
              width: 300,
              padding: '0 20px',
              borderRadius: '10px',
              border: '1px dashed #CCCCCC',
              textAlign: 'center'
            }}
          >
            <Typography
              style={{
                fontWeight: 600,
                margin: '20px 0 10px'
              }}
            >
              Change Password
            </Typography>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <KeyIcon style={{marginTop: '15px', color: '#999999'}} />
              <FormControl sx={{ m: 1, width: 'calc(100% - 16px)', opacity: '0.5' }} size="small" variant="standard">
                <InputLabel htmlFor="standard-adornment-password">Current Password</InputLabel>
                <Input
                  id="current-password"
                  type={showPassword ? 'text' : 'password'}
                  value={submitValue.currentPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        tabIndex={-1}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(e) => setSubmitValue({...submitValue, currentPassword: e.target.value})}
                />
              </FormControl>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <KeyIcon style={{marginTop: '15px', color: '#999999'}} />
              <FormControl sx={{ m: 1, width: 'calc(100% - 16px)', opacity: '0.5' }} size="small" variant="standard">
                <InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
                <Input
                  id="new-password"
                  type={showPassword ? 'text' : 'password'}
                  value={submitValue.newPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        tabIndex={-1}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(e) => setSubmitValue({...submitValue, newPassword: e.target.value})}
                />
              </FormControl>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <KeyIcon style={{marginTop: '15px', color: '#999999'}} />
              <FormControl sx={{ m: 1, width: 'calc(100% - 16px)', opacity: '0.5' }} size="small" variant="standard">
                <InputLabel htmlFor="standard-adornment-password">Confirm New Password</InputLabel>
                <Input
                  id="confirm-password"
                  type={showPassword ? 'text' : 'password'}
                  value={submitValue.confirmPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        tabIndex={-1}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(e) => setSubmitValue({...submitValue, confirmPassword: e.target.value})}
                />
              </FormControl>
            </Box>
            <Button 
              type="submit"
              variant="contained"
              fullWidth
              size="small"
              style={{
                margin: '20px 0',
                backgroundColor: 'rgb(0, 174, 239)'
              }}
              onClick={submitChangePassword}
            >
              Submit
            </Button>
          </Box>
          {success ? <Alert severity="success" sx={{my: 2}}>{success}</Alert> : ''}
          {alert ? <Alert severity="error" sx={{my: 2}}>{alert}</Alert> : ''}
        </Container>
        {loading ? <Loading loading={loading} setLoading={setLoading} /> : ''}
      </>
  );
};

export default ChangePassword;

