import React from 'react'
import { Modal, Box, Typography, CircularProgress } from '@mui/material';

const Loading = () => {
    return (
        <Modal
            open={true}
        >
            <Box style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}>
                <CircularProgress sx={{mb: 2}}/>
                <Typography variant='info' style={{color: 'white'}}>
                    Loading, please wait...
                </Typography>
            </Box>
        </Modal>
    )
}

export default Loading