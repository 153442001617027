import apiClient from './apiClient';

export const loadAllReportCard = async (type) => {
  try {
      const response = await apiClient.get('/ResourceLibraryPublication/getAllPublicationReportDetails/'+ type);
      return response.data;
  } catch (error) {
      console.error('Error creating news:', error);
      throw error;
  }
};

export const loadReportCard = async (type) => {
  try {
      const response = await apiClient.get('/ResourceLibraryPublication/getAllMainReports/'+ type);
      return response.data;
  } catch (error) {
      console.error('Error creating news:', error);
      throw error;
  }
};

export const loadReportCardId = async (id) => {
  try {
      const response = await apiClient.get('/ResourceLibraryPublication/getPublicationReportDetails/'+id);
      return response.data;
  } catch (error) {
      console.error('Error creating news:', error);
      throw error;
  }
};

export const createReportCard = async (content, file) => {
  const formData = new FormData();

  if (file) {
    formData.append('image', file);
  }

  formData.append('name', content.name);
  formData.append('year', content.year);
  formData.append('author', content.author);
  formData.append('type', content.type);
  
  try {
      const response = await apiClient.post('/ResourceLibraryPublication/postMainReport', formData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const editReportCard = async (content, file) => {
  const id = content.mainId
    const formData = new FormData();
    if (file) {
        formData.append('image', file);
    }

    formData.append('name', content.name);
    formData.append('year', content.year);
    formData.append('author', content.author);
    formData.append('type', content.type);

    try {
        const response = await apiClient.put('/ResourceLibraryPublication/updateMainReportById/'+id, formData);
        return response.data;
    } catch (error) {
        console.error('Error uploading:', error);
        throw error;
    } 
};

export const deleteReportCard = async (id) => {
  try {
    const response = await apiClient.delete('/ResourceLibraryPublication/deleteMainReport/'+id);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};

export const submitReport = async (id, content, file) => {
  const formData = new FormData();

  if (file) {
    formData.append('link', file);
  }

  formData.append('label', content.listPublicationsSubReportsParameters[id].label);
  formData.append('typeName', content.listPublicationsSubReportsParameters[id].typeName);
  formData.append('publicationMainReportId', content.listPublicationsSubReportsParameters[id].mainId);
  formData.append('linkText', content.listPublicationsSubReportsParameters[id].linkText);
  formData.append('GA_Action', content.listPublicationsSubReportsParameters[id].GA_Action);
  formData.append('GA_Category', content.listPublicationsSubReportsParameters[id].GA_Category);
  formData.append('GA_Label', content.listPublicationsSubReportsParameters[id].GA_Label);
  
  try {
      const response = await apiClient.post('/ResourceLibraryPublication/postSubReport', formData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const updateReport = async (id, content, file) => {
  const match = content.listPublicationsSubReportsParameters.find(
    (item) => item.subId === id
  );
  const formData = new FormData();

  if (file) {
    formData.append('link', file);
  }

  formData.append('label', match.label);
  formData.append('typeName', match.typeName);
  formData.append('publicationMainReportId', content.mainId);
  formData.append('linkText', match.linkText);
  formData.append('GA_Label', match.GA_Label);
  
  try {
      const response = await apiClient.put('/ResourceLibraryPublication/updateSubReportById/'+id, formData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const deleteReport = async (subId) => {
  try {
    const response = await apiClient.delete('/ResourceLibraryPublication/deleteSubReport/'+subId);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};

export const updateCardStatus = async (status) => {
  try {
    const response = await apiClient.put('/ResourceLibraryPublication/updateMainReportActiveStatus', status);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};

export const reorderCardList = async (newOrder) => {
  try {
    const response = await apiClient.put('/ResourceLibraryPublication/updateMainReportOrders', newOrder);
    return response.data;
  } catch (error) {
    console.error('Error creating news:', error);
    throw error;
  }
};