import React, { Fragment, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
// import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { useForm } from 'react-hook-form';
import {
  Tabs,
  Tab,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  FormControl
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import AboutIWRMImage from '../../assets/about/About-IWRM-Data-Portal.png'
// import SDGIndicatorImage from '../../assets/about/SDG-indicator-6.5.1.png'
import parse from 'html-react-parser';
import { loadContent } from '../../api/aboutServices';
import { loadFAQ } from '../../api/FAQServices';

const CustomTab = styled(Tab)(({ theme }) => ({
  fontSize: 12
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const customeStyle = {
  accordionHeader: {
    backgroundColor: '#F2F5F7',
    borderBottom: '2px solid lightgray'
  },
  accordionTitle: {
    color: '#09334B',
    fontSize: 14,
    fontWeight: 700
  },
  accordionContent: {
    fontSize: 12,
    fontWeight: 300,
    color: '#09334B',
    marginBottom: 10
  },
  content: {
    fontSize: 12,
    color: '#09334B',
    marginBottom: 10
  }
}

const About = () => {

  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState('panel0');
  const [aboutTabs, setAboutTabs] = useState([]);
  const [FAQ, setFAQ] = useState([]);

  const fetchAbout = async () => {
    try {
      const content = await loadContent();
      const FAQContent = await loadFAQ();
      setAboutTabs(content);
      setFAQ(FAQContent)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchAbout();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpand = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = data => {
    console.log(data);
    reset()
  };

  const filteredTabs = aboutTabs
  .filter(tab => tab.isActive !== false)
  .sort((a, b) => a.orderNumber - b.orderNumber);

  return (
    <Fragment>
      <Helmet>
        <title>IWRM Data Portal - About</title>
        <meta name="description" content="The objective of the IWRM Data Portal is to provide an easy access to the global data on the status of implementation of Integrated Water Resources Management (IWRM) to all interested stakeholders." />
        <meta name="keywords" content="IWRM, SDG 6, SDG 6.5.1, water management, sustainable development, about IWRM, about SDG 6, about SDG 6.5.1" />
      </Helmet>
      <div className="main-container" style={{ padding: '0px 20px' }}>
        <div className="one-column">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="publication-type-tabs">
              {filteredTabs.map((tab, index) => (
                <CustomTab key={tab.name} label={tab.name} {...a11yProps(index)} />
              ))}
              <CustomTab label="FAQ" {...a11yProps(filteredTabs.length)} />
            </Tabs>
          </Box>
          {filteredTabs.map((tab, index) => (
            <TabPanel value={value} key={tab.name} index={index}>
              <div className="two-columns">
                <div className="left-side">{parse(tab.content)}</div>
                <div className="right-side">
                  <img src={tab.img} alt={tab.name} style={{ width: '90%' }} />
                </div>
              </div>
            </TabPanel>
          ))}
          <TabPanel value={value} index={filteredTabs.length}>
            <div className="two-columns">
              <div className="left-side" style={{ marginRight: 5 }}>
                <div className="support-programme-title">FAQ</div>
                <div className="accordion-container">
                  {
                    FAQ
                    .filter(news => news.isActive !== false)
                    .sort((a, b) => a.orderNumber - b.orderNumber)
                    .map((item, index) => (
                      <Accordion key={item.name} expanded={expanded === 'panel'+ index} onChange={handleExpand('panel'+ index)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={customeStyle.accordionHeader}
                        >
                          <Typography style={customeStyle.accordionTitle}>{item.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {parse(item.content)}
                        </AccordionDetails>
                      </Accordion>
                    ))
                  }
                </div>
              </div>
              <div className="right-side" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 5 }}>
                <div className="support-programme-title">Do you have a question?</div>
                <Typography style={customeStyle.content}>Our Help Desk is ready to answer your questions. Please fill in your mail and what the question is about and we will get back to you.</Typography>
                <Box>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                      <TextField
                        label="Your Name"
                        variant="filled"
                        InputLabelProps={{
                          style: { fontSize: 11 }
                        }}
                        {...register("name", { required: "Name is required" })}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                      <TextField
                        label="Your Country"
                        variant="filled"
                        InputLabelProps={{
                          style: { fontSize: 11 }
                        }}
                        {...register("country", { required: "Country is required" })}
                        error={!!errors.country}
                        helperText={errors.country?.message}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                      <TextField
                        label="Your E-mail"
                        variant="filled"
                        InputLabelProps={{
                          style: { fontSize: 11 }
                        }}
                        type="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: "Invalid email address"
                          }
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                      <TextField
                        label="Write your questions here"
                        variant="filled"
                        InputLabelProps={{
                          style: { fontSize: 11 }
                        }}
                        multiline
                        rows={4}
                        {...register("questions", { required: "Please enter your question" })}
                        error={!!errors.questions}
                        helperText={errors.questions?.message}
                      />
                    </FormControl>
                    <Button type="submit" variant="contained" color="primary">
                      Send
                    </Button>
                  </form>
                </Box>
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
    </Fragment>
  )
}

export default About