import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { Container } from '@mui/system';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Lock from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import UN_Environment_Logo from '../assets/logo/un-environment-grey.png';
import Background from '../assets/Images/GettyImages-935751286.jpeg';
import { Typography, Alert } from '@mui/material';
import { login } from '../api/globalCMSServices';

const style = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundImage: "url(" + Background + ")",
  backgroundPosition: 'center',
  backgroundRepeat: 'no-Repeat',
  backgroundSize: 'cover'
}

const Login = () => {
  const navigate =  useNavigate();
  const [alert, setAlert] = useState(null)
  const [user, setUser] = useState({
    'email': '',
    'password': ''
  });
  const handleLogin = (e) => {
    e.preventDefault();
    const fetchUser = async () => {
      try {
        const response = await login(user);
        Cookies.set('authToken', response.token, { expires: 1 });
        Cookies.set('refreshToken', response.refreshToken, { expires: 1 });
        setAlert(null)
        navigate('/cms/home');
      } catch (error) {
        console.error(error);
        setAlert('Invalid username or password.')
      }
    };
    fetchUser();
  };
  return (
    <Container style={style} maxWidth={false}>
      <Stack 
        style={{height: '100%'}}
        spacing={{ xs: 1, sm: 2 }}
        direction="column"
        useFlexGap
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        <img style={{width: '150px', height: '150px'}} src={UN_Environment_Logo} alt='' />
        <Typography variant="h1" style={{color: 'white', fontSize: '24px'}}>IWRM Data Portal CMS</Typography>
        <Card sx={{ minWidth: 300, background: 'rgba(255, 255, 255, 0.60)', backdropFilter: 'blur(7px)', padding: '30px 30px 50px' }}>
          <form onSubmit={handleLogin}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '10px 0 20px' }}>
              <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <TextField
                id="Username"
                fullWidth
                label="Username"
                type='text'
                variant="standard"
                onChange={(e) => setUser({...user, email: e.target.value})}/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '10px 0 20px' }}>
              <Lock sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <TextField
                id="Password"
                fullWidth
                label="Password"
                type='password'
                variant="standard"
                onChange={(e) => setUser({...user, password: e.target.value})} />
            </Box>
            <Button type="submit" variant="contained" fullWidth size="large" style={{margin: '20px 0'}}>Login</Button>
          </form>
          <Link href="./forgot-password" style={{float: 'right'}}>Forgot password?</Link>
        </Card>
        {alert ? <Alert variant="filled" severity="error">{alert}</Alert> : ''}
      </Stack>
    </Container>
  )
}

export default Login