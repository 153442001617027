import UNEP_DHI_LOGO from '../../assets/logo/unep-dhi.svg'
import UN_ENVI_LOGO from '../../assets/logo/un-environment.svg'
import GWP_LOGO from '../../assets/logo/global-water-partnership-gwp.png'
import CWAS_LOGO from '../../assets/logo/clean-water-and-sanitation.svg'

export const menus = [
    {
        id: 0,
        name: 'Home',
        path: '/',
        icon: 'home',
        subnav: null
    },
    {
        id: 2,
        name: 'IWRM Data',
        path: '#',
        icon: null,
        subnav: [
            {
                id: 3,
                name: 'Interactive Map',
                path: '/interactive-map',
                icon: null,
                subnav: null
            },
            {
                id: 4,
                name: 'Country Reports',
                path: '/country-reports',
                icon: null,
                subnav: null
            }
        ]
    },
    {
      id: 3,
      name: 'Interactive Map',
      path: '/interactive-map',
      icon: null,
      subnav: null
    },
    {
      id: 4,
      name: 'Country Reports',
      path: '/country-reports',
      icon: null,
      subnav: null
    },
    {
        id: 5,
        name: 'Resource Library',
        path: '/publications/global-reports',
        icon: null,
        subnav: null
    },
    {
        id: 6,
        name: 'Support Programme',
        path: '/support-programme',
        icon: null,
        subnav: null
    },
    
    {
      id: 1,
      name: 'Data Collection',
      path: '/data-collection',
      icon: null,
      subnav: null
    },
    {
        id: 7,
        name: 'About',
        path: '/about',
        icon: null,
        subnav: null
    }
]

  export const supportOrganization = [
    {
        id: 1,
        alt: 'unep-dhi',
        class: 'py2',
        href: 'http://www.unepdhi.org/',
        src: UNEP_DHI_LOGO,
    },
    {
        id: 2,
        alt: 'un-envi',
        class: 'py2',
        href: 'https://www.unenvironment.org/',
        src: UN_ENVI_LOGO,
    },
    {
        id: 3,
        alt: 'gwp',
        class: 'py4',
        href: 'https://www.gwp.org/en/sdg6support/',
        src: GWP_LOGO,
    },
    {
        id: 4,
        alt: 'cwas',
        class: 'py2',
        href: 'https://www.sdg6data.org/',
        src: CWAS_LOGO,
    }
]