import React, { useEffect, useState } from 'react';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { Container } from '@mui/system';
import Grid from '@mui/system/Unstable_Grid';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import Key from '@mui/icons-material/Key';
import Logout from '@mui/icons-material/Logout';
import { deepPurple } from '@mui/material/colors';
import UN_Environment_Logo from '../../assets/logo/un-environment-grey.png';
import { getNavigation, refreshAuthToken } from '../../api/globalCMSServices';

const CMSHeader = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuClick, setMenuClick] = useState(null);
  const [mobileMenu, setMobileMenu] = useState([]);
  const [user, setUser] = useState(null)
  const open = Boolean(anchorEl);
  const openMenu = Boolean(menuClick);
  const [lastActivity, setLastActivity] = useState(null);

  const fetchMenu = async () => {
    try {
      const menu = await getNavigation();
      setMobileMenu(menu);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMenu();
  }, []);

  useEffect(() => {
    const token = Cookies.get('authToken');
    if (!token) {
      navigate('/login');
    } 
    const decodedToken = jwtDecode(token);
    const role = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    setUser({
      'name': decodedToken.name,
      'role': role
    })
  }, [navigate]);

  useEffect(() => {
    const checkInactivity = () => {
      const now = Date.now();
      const timeDiff = now - lastActivity;
      const maxInactivity = 60 * 60 * 1000;
      if (timeDiff > maxInactivity) {
        Cookies.remove('authToken');
        navigate('/login');
      }
    };
    const interval = setInterval(checkInactivity, 1000 * 60 * 60);
    return () => clearInterval(interval);
  }, [lastActivity, navigate]);

  

  useEffect(() => {
    const refreshToken = async () => {
      try {
        const authToken = Cookies.get('authToken');
        const refreshToken = Cookies.get('refreshToken');
        if (!authToken || !refreshToken) {
          navigate('/login');
          return;
        }
        const oldToken = {
          'token': authToken,
          'refreshToken': refreshToken
        }
        const response = await refreshAuthToken(oldToken);
        if (response && response.token && response.refreshToken) {
          Cookies.set('authToken', response.token, { expires: 1 });
          Cookies.set('refreshToken', response.refreshToken, { expires: 1 });
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error('Token refresh failed:', error);
        navigate('/login');
      }
    };

    const interval = setInterval(refreshToken, 1000 * 60 * 27);
    return () => clearInterval(interval);
  }, [navigate]);
  
  const accountClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const mobileMenuClick = (event) => {
    setMenuClick(event.currentTarget);
  };

  useEffect(() => {
    const resetTimer = () => setLastActivity(Date.now());

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);

    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
    setMenuClick(null);
  };

  const handleChangePassword = () => {
    navigate("/cms/change-password");
  };

  const handleLogout = () => {
    Cookies.remove('authToken');
    Cookies.remove('refreshToken');
    navigate("/login");
  };

  return (
    <Container style={{width: '100%', backgroundColor: '#00AEEF'}} className='CMSHeader' maxWidth={false}>
      <Grid container>
        <Grid xs={8}>
          <Box sx={{ display: 'flex', alignItems: 'center' }} className='CMSHeader'>
            <img className='CMSLogo' src={UN_Environment_Logo} alt='' />
            <Typography variant='body1' className='CMSAppName'>IWRM Data Portal CMS</Typography>
          </Box>
        </Grid>
        <Grid xs={4}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} className='CMSHeader'>
            <Typography variant='body1' className='CMSActiveUser'>{user ? user.name : ''}</Typography>
            <Tooltip title="Account settings">
              <IconButton
                onClick={accountClick}
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ bgcolor: deepPurple[500], width: 35, height: 35, fontSize: 16 }}>{user ? user.name.split(' ').map(word => word[0]).join('').slice(0, 2) : ''}</Avatar>
              </IconButton>
            </Tooltip>
            
            <IconButton
              className='CMSMobileMenuBtn'
              onClick={mobileMenuClick}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClose}>
              <Avatar sx={{bgcolor: deepPurple[500], fontSize: 16}}>{user ? user.name.split(' ').map(word => word[0]).join('').slice(0, 2) : ''}</Avatar> {user ? user.name : ''}
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleChangePassword}>
              <ListItemIcon>
                <Key fontSize="small" />
              </ListItemIcon>
              Change Password
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
          <Menu
            anchorEl={menuClick}
            id="account-menu"
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {
              mobileMenu
              .filter(nav => nav.isActive)
              .map(nav => (
                <MenuItem 
                  key={nav.key}
                  onClick={() => {
                    navigate(nav.link)
                  }} >
                  {nav.label}
                </MenuItem>
              ))
            }
          </Menu>
        </Grid>
      </Grid>
    </Container>
  )
}

export default CMSHeader