import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Container } from '@mui/system';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditNoteOutlined';
import TabIcon from '@mui/icons-material/Tab';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Typography, TextField, Divider, Tabs, Tab } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slide from '@mui/material/Slide';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { loadSubPage, loadSubPageId, createSubPage, editSubPage, updateSubPageStatus, reorderSubPage } from '../api/subPageServices';
import Loading from '../components/spinner/Loading';

const emptyObject = {
  id: '',
  name: '',
  isActive: false,
  orderNumber: '',
  GA_Action: 'tab_click',
  GA_Category: 'page_view',
  GA_Label: '',
}

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #F0f0f0',
  marginBottom: '23px'
});

const AntTab = styled(Tab)({
  textTransform: 'none'
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#33bfff',
    },
    success: {
      main: '#0ec917',
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CMSResourceLibrary = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [cardData, setCardData] = useState([]);
  const [createEditDialog, setCreateEditDialog] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedObject, setSelectedObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [object, setObject] = useState(emptyObject);

  const fetchSubPage = async () => {
    setLoading(true);
    try {
      const subPage = await loadSubPage();
      setCardData(subPage);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSubPage();
  }, []);

  const handleOnDragEnd = async (result) => {
    setLoading(true);
    const { destination, source } = result;
    if (!destination) return;
    const items = Array.from(cardData);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    const data = items.map((item, index) => ({
      ...item,
      id: item.id,
      orderNumber: index + 1,
    }));
    const newOrder = data.map((item) => ({
      id: item.id,
      orderNumber: item.orderNumber,
    }));
    try {
      await  reorderSubPage(newOrder);
      setCardData(data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const closeCreateEditDialog = () => {
    setCreateEditDialog(false);
  };

  const handleAddButton = () => {
    setCreateEditDialog(true);
    setObject(emptyObject)
    setSelectedObject(null)
  };

  const handleActivateButton = async (id, current) => {
    setLoading(true);
    const newStatus = !current;
    const status = {
      'id': id,
      'isActive': newStatus
    }
    try {
      await  updateSubPageStatus(status);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleEditButton = async (id) => {
    setLoading(true);
    setObject(emptyObject);
    setCreateEditDialog(true);
    setSelectedObject(id)
    try {
      const subPageId = await loadSubPageId(id);
      setObject(subPageId);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleCreateTab = async (e) => {
    setLoading(true);
    setCreateEditDialog(false);
    e.preventDefault();
    try {
      await createSubPage(object);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleEditTab = async (e) => {
    setLoading(true);
    setCreateEditDialog(false);
    e.preventDefault();
    try {
      await editSubPage(object);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const goToReportType = () => {
    navigate("/cms/resource-library/report-type");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Box>
          <AntTabs 
            value={value}
            onChange={handleChange}
            allowScrollButtonsMobile
          >
            <AntTab 
              label='Sub-Page'
              icon={<TabIcon />}
              iconPosition="start"
            />
            <AntTab 
              label='Report Type'
              icon={<SettingsIcon />}
              iconPosition="start"
              onClick={goToReportType} 
            />
          </AntTabs>
        </Box>
        <Grid container spacing={2} >
          <Grid item xs={12} style={{
            display: 'flex',
            flexDirection: 'row'
          }}>
            <Typography variant="h6" xs={{ mr: 'auto' }}>Sub Page</Typography>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ color: 'white', borderRadius: '50px' }}
              startIcon={<AddIcon />}
              onClick={handleAddButton}
            >
              Add <span className='CMSMobileTextHide'>&nbsp;New Sub Page</span>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Container className="dndContainer">
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="cards">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {cardData.sort((a, b) => a.orderNumber - b.orderNumber).map((data, index) => (
                        <Draggable key={data.name + index} draggableId={data.name + index} index={index}>
                          {(provided) => (
                            <Box
                              className="dndList noImage"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Tooltip title="Drag to re-order">
                                <IconButton
                                  edge="start"
                                  aria-label="drag"
                                  disableRipple
                                  className='CMSMobileDragIcon'
                                  {...provided.dragHandleProps}
                                >
                                  <DragIndicatorIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Turn On/Off">
                                <Switch
                                  className='CMSMobileActiveSwitch'
                                  color="success"
                                  defaultChecked={data.isActive}
                                  onClick={() => handleActivateButton(data.id, data.isActive)}
                                />
                              </Tooltip>
                              <Typography sx={{ mr: 'auto' }} variant="body1" className='CMSMobileTitleList noImage'>
                                {data.name}
                              </Typography>
                              <span className='CMSInviShadow'></span>
                              {!(data.name === 'Result') ? (
                                <>
                                  <Tooltip title="Edit">
                                    <IconButton
                                      sx={{ ml: 2 }}
                                      edge="end"
                                      aria-label="edit"
                                      className='CMSMobileEditBtn'
                                      onClick={() => handleEditButton(data.id)}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Enter">
                                    <IconButton
                                      sx={{ ml: 2 }}
                                      edge="end"
                                      aria-label="edit"
                                      className='CMSMobileEnterBtn'
                                      onClick={() => navigate("/cms/resource-library/" + data.url)}
                                    >
                                      <ExitToAppIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              ) : '' }
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Container>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        maxWidth='md'
        fullWidth={true}
        fullScreen={fullScreen}
        open={createEditDialog}
        onClose={closeCreateEditDialog}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <React.Fragment>
              <Container 
                style={{
                    width: '100%',
                    height: '400px',
                    padding: '20px',
                    marginTop: '10px',
                    backgroundColor: 'whitesmoke',
                    borderRadius: '8px',
                    border: '1px dashed #CCCCCC',
                    overflowY: 'auto'
                }}
              >
                <Grid container spacing={2} className='jodit360'>
                    <Grid item xs={12}>
                        <Typography variant='h6'>{!(selectedObject === null) ? 'Edit' : 'Create'} sub-page</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                          id="Name"
                          fullWidth
                          label="Sub-page name"
                          type='text'
                          size='small'
                          style={{marginBottom: '15px', backgroundColor: 'white'}}
                          value={object.name}
                          onChange={(e) => setObject({...object, name: e.target.value})}
                      />
                      <Divider sx={{mb: 2}} />
                      <Box xs={12}>
                        <Typography variant='body2' sx={{mb: 2}}>Google Analytics for sub-page</Typography>
                        <TextField
                          id="eventCategory"
                          label="Event Category"
                          type='text'
                          size='small'
                          disabled
                          style={{marginBottom: '15px', backgroundColor: 'white'}}
                          value={object.GA_Category}
                        />
                        <br />
                        <TextField
                            id="eventName"
                            label="Event Name"
                            type='text'
                            size='small'
                            disabled
                            style={{marginBottom: '15px', backgroundColor: 'white'}}
                            value={object.GA_Action}
                        />
                        <br />
                        <TextField
                            id="eventLabel"
                            label="Event Label"
                            type='text'
                            size='small'
                            style={{marginBottom: '15px', backgroundColor: 'white'}}
                            value={object.GA_Label}
                            onChange={(e) => setObject({...object, GA_Label: e.target.value})}
                        />
                      </Box>
                    </Grid>
                </Grid>
              </Container>    
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  onClick={closeCreateEditDialog}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button 
                  onClick={!(selectedObject === null) ? handleEditTab : handleCreateTab}
                  disabled={
                    (!(object.name && object.GA_Label))
                  }
                >
                    Save
                </Button>
              </Box>
            </React.Fragment>
          </Box>
        </DialogContent>
      </Dialog>
      {loading ? <Loading loading={loading} setLoading={setLoading} /> : ''}     
    </ThemeProvider>
  );
}

export default CMSResourceLibrary