import React, {useState, useEffect} from 'react'
import { NavLink } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { loadNews } from '../../api/newsServices';

const loadBanners = async () => {
  try {
    const newsBanner = await loadNews();
    return(newsBanner);
  } catch (error) {
    console.error(error);
  }
};

const NextArrow = (props) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        width: '30px',
        height: '30px',
        position: 'relative',
        top: '-235px',
        right: '-95%',
        zIndex: 1
      }}
    >
      <ChevronRight sx={{ color: '#00aeef', cursor: 'pointer', fontSize: '54px' }} />
    </div>
  );
};

const PrevArrow = (props) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        width: '30px',
        height: '30px',
        position: 'relative',
        bottom: '-200px',
        zIndex: 1
      }}
    >
      <ChevronLeft sx={{ color: '#00aeef', cursor: 'pointer', fontSize: '54px' }} />
    </div>
  );
};

const NewSlider = () => {
  const [newsData, setNewsData] = useState([]);
  useEffect(() => {
    const fetchNews = async () => {
      const data = await loadBanners();
      setNewsData(data);
    };
    fetchNews();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 4000
  };

  return (
    <Box sx={{ position: 'relative', marginBottom: '-70px' }}>
      <Slider {...settings}>
        {
          newsData?.filter(news => news.isActive !== false)
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .map((news, index) => (
              <div key={`${news.id || news.title}_${index}`}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '50%', height: '400px' }}>
                    <img
                      src={news.img}
                      alt={news.title}
                      style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                    />
                  </div>
                  <div style={{
                    width: '50%',
                    backgroundColor: '#DBE4E9',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    padding: '30px'
                  }}>
                    <h4
                      style={{
                        color: '#09334B',
                        fontWeight: 700,
                        fontSize: 24,
                        lineHeight: '30px'
                      }}>{news.name}</h4>
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: '#09334B',
                        marginRight: 30,
                      }}
                    >{news.content}</p>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px'
                      }}
                    >
                      {
                        news.button.map(item => {
                          const isExternal = item.link.startsWith('http://') || item.link.startsWith('https://');
                            return isExternal ? (
                              <a
                                key={item.buttonText}
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: 'none',
                                  backgroundColor: '#00aeef',
                                  color: 'white',
                                  padding: '10px 12px',
                                  borderRadius: '5px',
                                  fontSize: 12
                                }}
                              >
                                {item.buttonText}
                              </a>
                            ) : (
                              <NavLink
                                key={item.buttonText}
                                to={item.link}
                                style={{
                                  textDecoration: 'none',
                                  backgroundColor: '#00aeef',
                                  color: 'white',
                                  padding: '10px 12px',
                                  borderRadius: '5px',
                                  fontSize: 12
                                }}
                              >
                                {item.buttonText}
                              </NavLink>
                            );
                          })
                      }
                    </Box>
                  </div>
                </div>
              </div>
            )
          )
        }
      </Slider>
    </Box>
  )
}

export default NewSlider