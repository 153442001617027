import React from 'react';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/system';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });



const NewsStep1 = ({newsObject, setNewsObject, setFile}) => {

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setNewsObject({...newsObject, img: reader.result});
                setFile(file)
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Container 
            style={{
                width: '100%',
                height: '350px',
                padding: '20px',
                backgroundColor: 'whitesmoke',
                borderRadius: '8px',
                border: '1px dashed #CCCCCC',
                overflowY: 'auto'
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h6'>Cover image and details</Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Box 
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            border: '1px solid #cccccc',
                            padding: '15px',
                            borderRadius: '4px',
                            backgroundColor: 'white'
                        }}
                    >
                        <Box style={{marginBottom: '10px'}}>
                        {newsObject.img ? (
                            <img
                                src={newsObject.img}
                                alt="Selected"
                                style={{
                                    width: '100%',
                                    height: '120px',
                                }}
                            />
                        ) : (
                            <Box
                                style={{
                                    border: '1px dashed #cccccc',
                                    width: '100%',
                                    height: '120px',
                                    borderRadius: '2px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <PhotoSizeSelectActualIcon style={{color: '#cccccc', fontSize: '50px'}} />
                            </Box>
                        )}
                    </Box>
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        size='small'
                        startIcon={<PhotoSizeSelectActualIcon />}
                        style={{color: 'white'}}
                    >
                        Browse file
                        <VisuallyHiddenInput type="file" accept="image/*" onChange={handleImageChange} />
                    </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={7} textAlign="right">
                    <TextField
                        id="newsTitle"
                        fullWidth
                        label="Title"
                        type='text'
                        size='small'
                        style={{marginBottom: '15px', backgroundColor: 'white'}}
                        value={newsObject.name}
                        onChange={(e) => setNewsObject({...newsObject, name: e.target.value})}
                    />
                    <TextField
                        id="newsDesc"
                        fullWidth
                        label="Description"
                        type='text'
                        size='small'
                        multiline
                        rows={8}
                        style={{backgroundColor: 'white'}}
                        value={newsObject.content}
                        onChange={(e) => setNewsObject({...newsObject, content: e.target.value})}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

export default NewsStep1