import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Container } from '@mui/system';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slide from '@mui/material/Slide';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { grey, red } from '@mui/material/colors';
import HelpIcon from '@mui/icons-material/Help';
import TabIcon from '@mui/icons-material/Tab';
import AboutTabsStep1 from './partial/aboutTabsStep1';
import AboutTabsStep2 from './partial/aboutTabsStep2';
import { loadContent, loadContentId, createContent, editContent, updateStatus, deleteContent, reorderContent } from '../api/aboutServices';
import Loading from '../components/spinner/Loading';

const emptyObject = {
  id: '',
  name: '',
  content: '',
  isActive: false,
  orderNumber: '',
}

const steps = ['Tab & Description', 'Cover Image'];

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #F0f0f0',
  marginBottom: '23px'
});

const AntTab = styled(Tab)({
  textTransform: 'none'
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#33bfff',
    },
    success: {
      main: '#0ec917',
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CMSAbout = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [cardData, setCardData] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [createEditDialog, setCreateEditDialog] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selected, setSelected] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [object, setObject] = useState(emptyObject);
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null);
  const [deleteName, setDeleteName] = useState(null);
  const cancelColor = grey[500];
  const deleteColor = red[500];

  const fetchAbout = async () => {
    setLoading(true);
    try {
      const content = await loadContent();
      setCardData(content);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchAbout();
  }, []);
  
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (<AboutTabsStep1 object={object} setObject={setObject} />);
      case 1:
        return (<AboutTabsStep2 object={object} setObject={setObject} file={file} setFile={setFile} />);
      default:
        return (null);
    }
  };

  const handleOnDragEnd = async (result) => {
    setLoading(true);
    const { destination, source } = result;
    if (!destination) return;
    const items = Array.from(cardData);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    const data = items.map((item, index) => ({
      ...item,
      id: item.id,
      orderNumber: index + 1,
    }));
    const newOrder = data.map((item) => ({
      id: item.id,
      orderNumber: item.orderNumber,
    }));
    try {
      await  reorderContent(newOrder);
      setCardData(data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const openDeleteDialog = (id) => {
    const item = cardData.find(obj => obj.id === id);
    setSelected(id)
    setDeleteName(item.name)
    setDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const closeCreateEditDialog = () => {
    setCreateEditDialog(false);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAddButton = () => {
    setCreateEditDialog(true);
    setActiveStep(0);
    setObject(emptyObject)
    setSelected(null)
  };

  const handleEditButton = async (id) => {
    setLoading(true);
    setActiveStep(0);
    setObject(emptyObject);
    setCreateEditDialog(true);
    setSelected(id);
    try {
      const contentId = await loadContentId(id);
      setObject(contentId);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleActivateButton = async (id, current) => {
    setLoading(true);
    const newStatus = !current;
    const status = {
      'id': id,
      'isActive': newStatus
    }
    try {
      await  updateStatus(status);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleCreate = async (e) => {
    setLoading(true);
    setCreateEditDialog(false);
    e.preventDefault();

    try {
      await createContent(object, file);
      setFile(null);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleEdit = async (e) => {
    setLoading(true);
    setCreateEditDialog(false);
    e.preventDefault();
    try {
      await editContent(object, file);
      setFile(null);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    setDeleteDialog(false);
    try {
      await  deleteContent(id);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error Deleting:', error);
    }
    const updatedItems = cardData.filter(item => item.id !== id);
    setCardData(updatedItems)
    setLoading(false);
  };

  const goToFAQ = () => {
    navigate("/cms/about/FAQ");
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Box>
          <AntTabs 
            value={value}
            onChange={handleChange}
            allowScrollButtonsMobile
          >
            <AntTab 
              label='Tab List'
              icon={<TabIcon />}
              iconPosition="start"
            />
            <AntTab 
              label='FAQ'
              icon={<HelpIcon />}
              iconPosition="start"
              onClick={goToFAQ} 
            />
          </AntTabs>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6">Tab list</Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ color: 'white', borderRadius: '50px' }}
              startIcon={<AddIcon />}
              onClick={handleAddButton}
            >
              Add <span className='CMSMobileTextHide'>&nbsp;New Tab</span>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Container className="dndContainer">
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="cards">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {cardData.sort((a, b) => a.orderNumber - b.orderNumber).map((data, index) => (
                        <Draggable key={data.name + index} draggableId={data.name + index} index={index}>
                          {(provided) => (
                            <Box
                              className="dndList noImage"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Tooltip title="Drag to re-order">
                                <IconButton
                                  className='CMSMobileDragIcon'
                                  edge="start"
                                  aria-label="drag"
                                  disableRipple
                                  {...provided.dragHandleProps}
                                >
                                  <DragIndicatorIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Turn On/Off">
                                <Switch
                                  className='CMSMobileActiveSwitch'
                                  color="success"
                                  defaultChecked={data.isActive}
                                  onClick={() => handleActivateButton(data.id, data.isActive)}
                                />
                              </Tooltip>
                              <span className='CMSInviShadow'></span>
                              <Typography sx={{ mr: 'auto' }} variant="body1" className='CMSMobileTitleList noImage'>
                                {data.name}
                              </Typography>
                              <Tooltip title="Edit">
                                <IconButton
                                  sx={{ ml: 2 }}
                                  edge="end"
                                  aria-label="edit"
                                  className='CMSMobileEditBtn'
                                  onClick={() => handleEditButton(data.id)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  sx={{ ml: 2, mr: 1 }}
                                  edge="end"
                                  aria-label="delete"
                                  disabled={data.name === 'FAQ'}
                                  className='CMSMobileDeleteBtn'
                                  onClick={() => openDeleteDialog(data.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        fullScreen={fullScreen}
        open={deleteDialog}
        onClose={closeDeleteDialog}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete News"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to delete &ldquo;<strong style={{color: '#33bfff'}}>{deleteName}</strong>&rdquo;?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} style={{color: cancelColor}}>
            Cancel
          </Button>
          <Button onClick={() => handleDelete(selected)} style={{color: deleteColor}}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth='md'
        fullWidth={true}
        fullScreen={fullScreen}
        open={createEditDialog}
        onClose={closeCreateEditDialog}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
        disableEnforceFocus
        disableRestoreFocus
      >
        <DialogContent>
          <Box sx={{ width: '100%' }} style={{paddingTop: '105px'}}>
            <Stepper activeStep={activeStep} alternativeLabel style={{backgroundColor: 'whitesmoke', padding: '20px', position: 'absolute', top: 0, left: 0, right: 0}}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  onClick={closeCreateEditDialog}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Button 
                  onClick={activeStep === steps.length - 1 ? (!(selected === null) ? handleEdit : handleCreate) : handleNext}
                  disabled={
                    (activeStep === 0 && !(object.name && object.content)) ||
                    (activeStep === 1 && !(object.img))
                  }
                >
                    {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                </Button>
              </Box>
            </React.Fragment>
          </Box>
        </DialogContent>
      </Dialog>   
      {loading ? <Loading loading={loading} setLoading={setLoading} /> : ''}  
    </ThemeProvider>
  );
};

export default CMSAbout;
