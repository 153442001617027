import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Container } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Grid, Button, IconButton, Tooltip, Box, Typography, Switch} from '@mui/material/';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slide from '@mui/material/Slide';
import { grey, red } from '@mui/material/colors';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { styled } from '@mui/material/styles';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Editor from '../components/editor/Editor';
import { loadCover, updateCover, loadContent, loadContentId, uploadContent, editContent, deleteContent, updateStatus, reorderContent } from '../api/supportServices';
import Loading from '../components/spinner/Loading';

const emptySupportObject = {
  id: '',
  name: '',
  content: '',
  isActive: false,
  orderNumber: ''
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#33bfff',
    },
    success: {
      main: '#0ec917',
    },
    secondary: {
      main: '#90a4ae',
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CMSSupportProgramme = () => {
  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [createEditDialog, setCreateEditDialog] = useState(false);
  const [selectedSupport, setSelectedSupport] = useState(null);
  const [object, setObject] = useState(emptySupportObject);
  const [coverImg, setCoverImg] = useState('');
  const [tabValue, setTabValue] = useState('1');
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null);
  const [deleteName, setDeleteName] = useState(null);
  const cancelColor = grey[500];
  const deleteColor = red[500];

  const fetchImg = async () => {
    setLoading(true);
    try {
      const cover = await loadCover();
      setCoverImg(cover);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const fetchSupport = async () => {
    setLoading(true);
    try {
      const content = await loadContent();
      setCardData(content);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchImg();
    fetchSupport();
  }, []);

  const handleDeleteDialog = (id) => {
    const item = cardData.find(obj => obj.id === id);
    setSelectedSupport(id)
    setDeleteName(item.name)
    setDeleteDialog(true);
  };

  const handleAddDialog = () => {
    setCreateEditDialog(true);
    setObject(emptySupportObject)
    setSelectedSupport(null)
  };

  const handleEditDialog = async (id) => {
    setLoading(true);
    setObject(emptySupportObject)
    setCreateEditDialog(true);
    setSelectedSupport(id);
    try {
      const contentId = await loadContentId(id);
      setObject(contentId);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const closeCreateEditDialog = () => {
    setCreateEditDialog(false);
  };

  const handleActivateButton = async (id, current) => {
    setLoading(true);
    const newStatus = !current;
    const status = {
      'id': id,
      'isActive': newStatus
    }
    try {
      await  updateStatus(status);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleOnDragEnd = async (result) => {
    setLoading(true);
    const { destination, source } = result;
    if (!destination) return;
    const items = Array.from(cardData);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    const data = items.map((item, index) => ({
      ...item,
      id: item.id,
      orderNumber: index + 1,
    }));
    const newOrder = data.map((item) => ({
      id: item.id,
      orderNumber: item.orderNumber,
    }));
    try {
      await  reorderContent(newOrder);
      setCardData(data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleCreate = async (e) => {
    setLoading(true);
    setCreateEditDialog(false);
    e.preventDefault();

    try {
      await uploadContent(object);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleEdit = async (e) => {
    setLoading(true);
    setCreateEditDialog(false);
    e.preventDefault();

    try {
      await editContent(object);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    setDeleteDialog(false);
    try {
      await  deleteContent(id);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error Deleting:', error);
    }
    const updatedItems = cardData.filter(item => item.id !== id);
    setCardData(updatedItems)
    setLoading(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setCoverImg({img: reader.result});
          setFile(file);
        };
        reader.readAsDataURL(file);
    }
  };

  const handleUpdateCover = async (e) => {
    setLoading(true);
    setCreateEditDialog(false);
    e.preventDefault();

    try {
      await updateCover(file);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleContentChange = (value) => {
    setObject({ ...object, content: value })
  };

  return (
    <ThemeProvider theme={theme}>
      <TabContext value={tabValue}>
        <Container>
          <TabList onChange={handleTabChange}> 
            <Tab style={{textTransform: 'none'}} icon={<HorizontalSplitIcon />} iconPosition="start" label="Programme List" value='1' />
            <Tab style={{textTransform: 'none'}} icon={<InsertPhotoIcon />} iconPosition="start" label="Cover Image" value='2' />
          </TabList> 
        </Container>
        <TabPanel value='1' style={{padding:'24px 0'}}>
          <Container>
            <Grid container spacing={2}>
              
              <Grid item xs={9} sm={6}>
                <Typography variant="h6">IWRM Support Programme</Typography>
              </Grid>
              <Grid item xs={3} sm={6} textAlign="right">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ color: 'white', borderRadius: '50px' }}
                  startIcon={<AddIcon />}
                  onClick={handleAddDialog}
                >
                  Add <span className='CMSMobileTextHide'>&nbsp;Support Programme</span>
                </Button>
              </Grid>
              <Grid item xs={12}>
              <Container className="dndContainer">
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="cards">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {cardData.sort((a, b) => a.orderNumber - b.orderNumber).map((data, index) => (
                            <Draggable key={data.name + index} draggableId={data.name + index} index={index}>
                              {(provided) => (
                                <Box
                                  className="dndList noImage"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <Tooltip title="Drag to re-order">
                                    <IconButton
                                      className='CMSMobileDragIcon'
                                      edge="start"
                                      aria-label="drag"
                                      disableRipple
                                      {...provided.dragHandleProps}
                                    >
                                      <DragIndicatorIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Turn On/Off">
                                    <Switch
                                      className='CMSMobileActiveSwitch'
                                      color="success"
                                      defaultChecked={data.isActive}
                                      onClick={() => handleActivateButton(data.id, data.isActive)}
                                    />
                                  </Tooltip>
                                  <span className='CMSInviShadow'></span>
                                  <Typography sx={{ mr: 'auto' }} variant="body1" className='CMSMobileTitleList noImage'>
                                    {data.name}
                                  </Typography>
                                  <Tooltip title="Edit">
                                    <IconButton
                                      sx={{ ml: 2 }}
                                      edge="end"
                                      aria-label="edit"
                                      className='CMSMobileEditBtn'
                                      onClick={() => handleEditDialog(data.id)}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete">
                                    <IconButton
                                      sx={{ ml: 2, mr: 1 }}
                                      edge="end"
                                      aria-label="delete"
                                      className='CMSMobileDeleteBtn'
                                      onClick={() => handleDeleteDialog(data.id)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Container>
              </Grid>
            </Grid>
          </Container>
        </TabPanel>
        <TabPanel value='2' style={{padding:'24px 0'}}>
          <Container>
            <Box 
              style={{
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid #cccccc',
                  padding: '15px',
                  borderRadius: '4px',
                  backgroundColor: 'white'
              }}
            >
              <Box style={{marginBottom: '10px'}}>
                {coverImg.img ? (
                  <img
                      src={coverImg.img}
                      alt="Selected"
                      style={{
                          width: '100%',
                      }}
                      
                  />
                ) : (
                  <Box
                      style={{
                          border: '1px dashed #cccccc',
                          width: '100%',
                          borderRadius: '2px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                      }}
                      className='CMSCoverHolderSize'
                  >
                      <PhotoSizeSelectActualIcon style={{color: '#cccccc', fontSize: '50px'}} />
                  </Box>
                )}
              </Box>
              <Box 
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'end',
                  justifyContent: 'end',
                }}
              >
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    size='medium'
                    startIcon={<PermMediaIcon />}
                    color='secondary'
                    style={{color: 'white', textTransform: 'none'}}
                >
                  Browse <span className='CMSMobileTextHide'>&nbsp;Cover Image</span>
                  <VisuallyHiddenInput type="file" accept="image/*" onChange={handleImageChange} />
                </Button>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    size='medium'
                    disabled={!(coverImg)}
                    style={{color: 'white', textTransform: 'none', marginLeft: 'auto'}}
                    onClick={handleUpdateCover}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Container>
        </TabPanel>  
      </TabContext>
                    
      <Dialog
        fullScreen={fullScreen}
        open={deleteDialog}
        onClose={closeDeleteDialog}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete Content"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to delete &ldquo;<strong style={{color: '#33bfff'}}>{deleteName}</strong>&rdquo;?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} style={{color: cancelColor}}>
            Cancel
          </Button>
          <Button onClick={() => handleDelete(selectedSupport)} style={{color: deleteColor}}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth='md'
        fullWidth={true}
        fullScreen={fullScreen}
        open={createEditDialog}
        onClose={closeCreateEditDialog}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
        disableEnforceFocus
        disableRestoreFocus
      >
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <React.Fragment>
            <Container 
                style={{
                    width: '100%',
                    height: '550px',
                    padding: '20px',
                    marginTop: '10px',
                    backgroundColor: 'whitesmoke',
                    borderRadius: '8px',
                    border: '1px dashed #CCCCCC',
                    overflowY: 'auto'
                }}
            >
                <Grid container spacing={2} className='jodit360'>
                    <Grid item xs={12}>
                        <Typography variant='h6'>{!(selectedSupport === null) ? 'Edit' : 'Create'} Content</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="newsTitle"
                            fullWidth
                            label="Title"
                            type='text'
                            size='small'
                            style={{marginBottom: '15px', backgroundColor: 'white'}}
                            value={object.name}
                            onChange={(e) => setObject({...object, name: e.target.value})}
                        />
                        <Editor object={object} onContentChange={(value) => handleContentChange(value)} />
                    </Grid>
                </Grid>
            </Container>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  onClick={closeCreateEditDialog}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button 
                  onClick={!(selectedSupport === null) ? handleEdit : handleCreate}
                  disabled={
                    !(object.name && object.content)
                  }
                >
                    Save
                </Button>
              </Box>
            </React.Fragment>
          </Box>
        </DialogContent>
      </Dialog>
      {loading ? <Loading loading={loading} setLoading={setLoading} /> : ''}
    </ThemeProvider>
  )
}

export default CMSSupportProgramme