import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Box, Button, Tab, Typography, TextField, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { styled } from '@mui/material/styles';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import CloseIcon from '@mui/icons-material/Close';
import { loadReportCardId, submitReport, updateReport, deleteReport } from '../../api/reportListServices';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const RLStep2 = ({object, setObject, refList, setRefList, file, setFile, setLoading, disabled, setDisabled}) => {
  const navigate = useNavigate();
  const [uniqueActiveTypes, setUniqueActiveTypes] = useState([]);
  const [activeStep, setActiveStep] = useState('0');
  const [activeRow, setActiveRow] = useState(null);

  useEffect(() => {
    const { listPublicationsSubReportsParameters: download } = object;
    if (!download || download.length === 0) return;
  
    const uniqueTypesArray = Array.from(
      new Set(download.map(item => item.typeName).filter(Boolean))
    ).map(typeName => ({
      GA_Action: download.find(obj => obj.typeName === typeName)?.GA_Action || "",
      GA_Category: download.find(obj => obj.typeName === typeName)?.GA_Category || "",
      typeName: download.find(obj => obj.typeName === typeName)?.typeName || "",
      fileType: download.find(obj => obj.typeName === typeName)?.fileType || ""
    }));
  
    setRefList(prevAvailableTypes =>
      prevAvailableTypes.map(typeNameObj => ({
        ...typeNameObj,
        isActive: uniqueTypesArray.some(unique => unique.typeName === typeNameObj.typeName)
      }))
    );
  
    setUniqueActiveTypes(uniqueTypesArray);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const handleActivateTab = (typeName, fileType, GA_Action, GA_Category) => {
    setUniqueActiveTypes(prevTypes => {
      const alreadyExists = prevTypes.some(item => item.typeName === typeName);
      if (!alreadyExists) {
        const updatedTypes = [...prevTypes, { typeName, fileType, GA_Action, GA_Category}];
        setActiveStep(String(updatedTypes.length - 1));
        return updatedTypes;
      }
      return prevTypes;
    });
  
    setRefList(prevButtons =>
      prevButtons.map(button => 
        button.typeName === typeName ? { ...button, isActive: true } : button
      )
    );
  };

  const deleteTab = (e, indexToRemove) => {
    e.stopPropagation();
  
    const typeNameToRemove = uniqueActiveTypes[indexToRemove]?.typeName;
  
    setUniqueActiveTypes(prevTypes => {
      const updatedTypes = prevTypes.filter((_, index) => index !== indexToRemove);
      if (updatedTypes.length > 0) {
        setActiveStep(String(Math.min(indexToRemove, updatedTypes.length - 1)));
      } else {
        setActiveStep('0');
      }
      return updatedTypes;
    });
  
    setRefList(prevButtons =>
      prevButtons.map(button =>
        button.typeName === typeNameToRemove ? { ...button, isActive: false } : button
      )
    );
  };

  const handleChange = (event, newValue) => {
    setActiveStep(newValue);
  };

  const handleValueChange = (id, field, value) => {
    setObject(prevData => ({
      ...prevData,
      listPublicationsSubReportsParameters: prevData.listPublicationsSubReportsParameters.map(reports => {
        if ( reports.subId === id ) {
          return { ...reports, [field]: value };
        }
        return reports;
      }),
    }));
  };

  const addNewField = () => {
    setDisabled(true)
    const value = uniqueActiveTypes[activeStep];
    const download = object.listPublicationsSubReportsParameters || [];
    setActiveRow('temp_'+download.length)
    setObject({
      ...object,
      listPublicationsSubReportsParameters: [
        ...download,
        { 
          'GA_Action': value.GA_Action,
          'GA_Category': value.GA_Category,
          'GA_Label': '',
          'subId': 'temp_'+download.length,
          'label': '',
          'mainId': object.mainId,
          'language': '',
          'link': '',
          'fileType': value.fileType,
          'typeName': value.typeName,
        }
      ]
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (['pdf', 'zip', 'ppt', 'pptx', 'xls', 'xlsx'].includes(fileExtension)) {
        setObject(prevData => ({
          ...prevData,
          listPublicationsSubReportsParameters: prevData.listPublicationsSubReportsParameters.map((report, index) => {
            if (index === prevData.listPublicationsSubReportsParameters.length - 1) {
              return { ...report, link: file.name };
            }
            return report;
          }),
        }));
        setFile(file);
      } else {
        alert('Unsupported file type! Please upload PDF, ZIP, PPT, PPTX, XLS, or XLSX.');
      }
    }
  };

  const uploadReport = async (subId) => {
    setLoading(true);
    const id = Number(subId.split("_")[1]);
    try {
      await submitReport(id, object, file);
      setFile(null);
      setDisabled(false)
      try {
        const response = await loadReportCardId(object.mainId);
        setObject(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
        console.error('Error uploading:', error);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  }

  const editReport = async (subId) => {
    setActiveRow(subId);
    setDisabled(true)
  }

  const reuploadReport = async (subId) => {
    setLoading(true);
    try {
      await updateReport(subId, object, file);
      setFile(null);
      setActiveRow(null);
      setDisabled(false)
      try {
        const response = await loadReportCardId(object.mainId);
        setObject(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
        console.error('Error uploading:', error);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  }

  const deleteReportSubId = async (subId) => {
    setLoading(true);
    if (typeof subId === 'string' && subId.includes('temp')) {
      setObject(prevData => ({
        ...prevData,
        listPublicationsSubReportsParameters: prevData.listPublicationsSubReportsParameters
        .filter((report) => report.subId !== subId)
        .map(reports => {
          return reports;
        }),
      }));
      setDisabled(false)
    } else {
      try {
        await  deleteReport(subId);
        setActiveRow(null);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
        console.error('Error Deleting:', error);
      }
      setObject(prevData => ({
        ...prevData,
        listPublicationsSubReportsParameters: prevData.listPublicationsSubReportsParameters
        .filter((report) => report.subId !== subId)
        .map(reports => {
          return reports;
        }),
      }));
      setDisabled(false)
    }
    setLoading(false);
  }

  return (
    <Container
      style={{
        margin: '-10px -10px 0',
        width: 'calc(100% + 20px)',
        height: '450px',
        padding: '0',
        backgroundColor: 'whitesmoke',
        borderRadius: '8px',
        overflowY: 'hidden',
        border: '1px dashed #CCCCCC',
      }}
    >
      <Grid container>
        <Grid 
          item xs={12}
          style={{ 
            backgroundColor: '#DDDDDD',
            padding: '5px 5px 10px',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          }}
        >
            <Typography variant='h6' style={{margin: '0 0 -10px 5px'}}>Action Name</Typography>
            <Typography variant='caption' style={{margin: '4px 0 0 5px'}}>Please select action name you want to input in this report</Typography>
            <Box 
              style={{
                width: '100%',
                overflowY: 'auto',
                display: 'flex',
              }}
            >
              {refList.map((item, index) => (
                <Button
                  key={'availableBtn-'+index}
                  variant="contained"
                  style={{
                    height: '36px',
                    margin: '5px',
                    color: 'white',
                    textTransform: 'none',
                    whiteSpace: 'noWrap',
                    padding: '0 10px',
                    flexGrow: 0,
                    flexShrink: 0
                  }}
                  disabled={disabled || item.isActive === true}
                  onClick={() => handleActivateTab(item.typeName, item.fileType, item.GA_Action, item.GA_Category)}
                >
                  {item.typeName}
                </Button>
              ))}
            </Box>
        </Grid>
        <Grid item xs={12}>
          <TabContext value={activeStep}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: 'white' }}>
              <TabList onChange={handleChange} variant="scrollable" aria-label="lab API tabs example">
                {uniqueActiveTypes.map((item, index) => (
                  <Tab label={item.typeName} value={String(index)} disabled={disabled} key={'activeTab-'+index} style={{ textTransform: 'none', paddingRight: '35px', }} icon={
                    <CloseIcon 
                      style={{ position: 'absolute', right: '5px', color: 'red', top: '23px' }}
                      
                      onClick={(e) => deleteTab(e, index)}
                    />} 
                  />
                ))}
              </TabList>
            </Box>
            {uniqueActiveTypes.map((item, index) => {
              const matchingDataArray = object.listPublicationsSubReportsParameters?.filter(param => param.typeName === item.typeName);
              return (
                <TabPanel style={{height: '220px', overflowY: 'auto'}} key={'activeContent-' + index} value={String(index)}>
                  {matchingDataArray?.length > 0 ? (
                    matchingDataArray.map((matchingData, innerIndex) => (
                      <Grid container spacing={1} className='reportGridSetup' key={'innerContent-' + innerIndex}>
                        {innerIndex === 0 ? null : (
                          <Grid item xs={12}>
                            <hr style={{ border: 'none', borderTop: '1px solid rgb(221, 221, 221)' }} />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={5} style={{position:'relative'}}>
                          <TextField
                            required
                            fullWidth
                            label="Link"
                            value={matchingData.fileType !== 'url/link' ? matchingData.link || '' : matchingData.linkText || ''}
                            disabled={matchingData.fileType !== 'url/link' || matchingData.subId !== activeRow}
                            onChange={(e) => handleValueChange(matchingData.subId, 'linkText', e.target.value)}
                            type="text"
                            size="small"
                            style={{ backgroundColor: 'white' }}
                          />
                          {
                            matchingData.fileType !== 'url/link' ? (
                              <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                size='large'
                                startIcon={<DriveFolderUploadIcon className='browseReportIcon' />}
                                className='browseReportFile'
                                disabled={matchingData.subId !== activeRow}
                              >
                                <span className='hidden'>Browse</span>
                                <VisuallyHiddenInput type="file" accept=".pdf,.zip,.ppt,.pptx,.xls,.xlsx" onChange={handleFileChange} />
                              </Button>
                            ) : ''
                          }
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            required
                            fullWidth
                            label="Report Label"
                            value={matchingData.label || ''}
                            onChange={(e) => handleValueChange(matchingData.subId, 'label', e.target.value)}
                            type="text"
                            size="small"
                            style={{ backgroundColor: 'white' }}
                            disabled={matchingData.subId !== activeRow}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            required
                            fullWidth
                            label="GA Event Label"
                            value={matchingData.GA_Label || ''}
                            onChange={(e) => handleValueChange(matchingData.subId, 'GA_Label', e.target.value)}
                            type="text"
                            size="small"
                            style={{ backgroundColor: 'white' }}
                            disabled={matchingData.subId !== activeRow}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2} style={{display: 'flex'}}>
                          {matchingData.subId !== activeRow ? (
                            <IconButton 
                              aria-label="update"
                              onClick={() => editReport(matchingData.subId)}
                              className='uploadReportBtn'
                              disabled={typeof matchingData.subId === 'string' && matchingData.subId.includes('temp') ? false : disabled}
                            >
                              <EditIcon />
                              <span className='hidden'>Edit</span>
                            </IconButton>
                          ) : ''}
                          {matchingData.subId === activeRow ? (
                            <IconButton 
                              aria-label="update"
                              onClick={() => {
                                if (typeof matchingData.subId === 'string' && matchingData.subId.includes('temp')) {
                                  uploadReport(matchingData.subId);
                                } else {
                                  reuploadReport(matchingData.subId);
                                }
                              }}
                              className='uploadReportBtn'
                            >
                              {typeof matchingData.subId === 'string' && matchingData.subId.includes('temp') ? <UploadFileIcon /> : <CheckCircleOutlineIcon /> }
                              <span className='hidden'>{ typeof matchingData.subId === 'string' && matchingData.subId.includes('temp') ? 'Upload' : 'Update'}</span>
                            </IconButton>
                          ) : ''}
                          <IconButton 
                            aria-label="delete"
                            onClick={() => deleteReportSubId(matchingData.subId)}
                            className='deleteReportBtn'
                            disabled={activeRow && matchingData.subId !== activeRow}
                          >
                            <DeleteIcon /> <span className='hidden'>Delete</span>
                          </IconButton>

                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <div>Please click the "+" button to add</div>
                  )}
                  <Box 
                    style={{
                      textAlign: 'center',
                      borderTop: '1px solid #DDDDDD',
                      paddingTop: '10px',
                      marginTop: '5px'
                    }}
                  >
                    <Button
                      disabled={disabled}
                      variant="contained"
                      size='small'
                      startIcon={<AddIcon style={{fontSize: '28px', marginLeft: '9px'}} />}
                      style={{
                        color: 'white',
                        borderRadius: '50px',
                        width: '40px',
                        minWidth: '40px',
                        height: '40px',
                        textAlign: 'center'
                      }}
                      onClick={addNewField}
                    />
                  </Box>
                </TabPanel>
              );
            })}
          </TabContext>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RLStep2;
