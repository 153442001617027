import { Container } from '@mui/system';

const CMSCountryReports = () => {
  return (
    <Container style={{height: 'calc(100% - 20px)'}}>
      <h1>Country Reports</h1>
    </Container>
  )
}

export default CMSCountryReports