import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from '@mui/system';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slide from '@mui/material/Slide';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { grey, red } from '@mui/material/colors';
import RLStep1 from './partial/RLStep1';
import RLStep2 from './partial/RLStep2';
import RLStep3 from './partial/RLStep3';
import Loading from '../components/spinner/Loading';
import { loadReportType } from '../api/reportTypeServices';
import { loadReportCard, loadReportCardId, createReportCard, deleteReportCard, editReportCard, updateCardStatus, reorderCardList } from '../api/reportListServices';


const theme = createTheme({
  palette: {
    primary: {
      main: '#33bfff',
    },
    success: {
      main: '#0ec917',
    },
  },
});

const steps = ['Content', 'Download', 'Summary'];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CMSSubPageItem = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const lastSegment = location.pathname.split('/').pop();
  const [cardData, setCardData] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [createEditDialog, setCreateEditDialog] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedObject, setSelectedObject] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [object, setObject] = useState({});
  const [file, setFile] = useState(null);
  const [refList, setRefList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteName, setDeleteName] = useState(null);
  const [disabled, setDisabled] = useState(false)
  const cancelColor = grey[500];
  const deleteColor = red[500];

  const emptyObject = {
    'mainId': '',
    'image': '',
    'name': '',
    'author': '',
    'year': '',
    'isActive': false,
    'orderNumber': '',
    'type': lastSegment,
    'ListPublicationsSubReportsParameters': []
  }

  useEffect(() => {
    const fetchReportType = async () => {
      setLoading(true);
      try {
        const reportType = await loadReportType();
        const updatedReportType = reportType.map(item => ({
          ...item,
          isActive: false
        }));
        setRefList(updatedReportType);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    
    const fetchReportList = async () => {
      setLoading(true);
      try {
        const reportList = await loadReportCard(lastSegment);
        setCardData(reportList);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    fetchReportType();
    fetchReportList();

  }, [lastSegment]);

  useEffect(()=>{
    if(selectedObject){
      const fetchNewObject = async () => {
        setLoading(true);
        try {
          const reportCard = await loadReportCardId(selectedObject);
          setObject(reportCard);
        } catch (error) {
          console.error(error);
        }
        setLoading(false);
      };
      fetchNewObject();
    }
  }, [selectedObject])

  const formattedString = lastSegment
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (<RLStep1 object={object} setObject={setObject} file={file} setFile={setFile} />);
      case 1:
        return (<RLStep2 object={object} setObject={setObject} refList={refList} setRefList={setRefList} file={file} setFile={setFile} setLoading={setLoading} disabled={disabled} setDisabled={setDisabled} />);
      case 2:
        return (<RLStep3 object={object} />);
      default:
        return (null);
    }
  };

  const handleOnDragEnd = async (result) => {
    setLoading(true);
    const { destination, source } = result;
    if (!destination) return;
    const items = Array.from(cardData);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    const data = items.map((item, index) => ({
      ...item,
      id: item.id,
      orderNumber: index + 1,
    }));
    const newOrder = data.map((item) => ({
      id: item.id,
      orderNumber: item.orderNumber,
    }));
    try {
      await  reorderCardList(newOrder);
      setCardData(data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const openDeleteDialog = (id) => {
    const data = cardData.find(obj => obj.id === id);
    setSelectedObject(id)
    setDeleteName(data.name)
    setDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const closeCreateEditDialog = () => {
    setCreateEditDialog(false);
    window.location.reload();
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAddCardButton = () => {
    setCreateEditDialog(true);
    setActiveStep(0);
    setObject(emptyObject);
    setSelectedObject(null);
  };

  const handleEditCardButton = async (id) => {
    setLoading(true);
    setObject(emptyObject)
    setCreateEditDialog(true);
    setActiveStep(0);
    setSelectedObject(id)
    try {
      const loadCardId = await  loadReportCardId(id);
      setObject(loadCardId);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleCreateCard = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      const response = await createReportCard(object, file);
      setSelectedObject(response.id)
      setFile(null);
      handleNext();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleEditCard = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      const response = await editReportCard(object, file);
      setSelectedObject(response.id)
      setFile(null);
      handleNext();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleDeleteCard = async (id) => {
    setLoading(true);
    setDeleteDialog(false);
    try {
      await  deleteReportCard(id);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error Deleting:', error);
    }
    const updatedItems = cardData.filter(item => item.id !== id);
    setCardData(updatedItems)
    setLoading(false);
  };

  const handleDone = () => {
    closeCreateEditDialog();
  }

  const handleActivateButton = async (id, current) => {
    setLoading(true);
    const newStatus = !current;
    const status = {
      'id': id,
      'isActive': newStatus
    }
    try {
      await  updateCardStatus(status);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Grid container spacing={2} >
          <Grid item xs={12} style={{
            display: 'flex',
            flexDirection: 'row'
          }}>
            <Tooltip title="Back">
              <IconButton
                sx={{ ml: 0, mr: 1 }}
                edge="end"
                aria-label="edit"
                onClick={() => navigate("/cms/resource-library/")}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" xs={{ mr: 'auto' }} style={{marginTop: '4px'}}>{formattedString}</Typography>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ color: 'white', borderRadius: '50px' }}
              startIcon={<AddIcon />}
              onClick={handleAddCardButton}
            >
              Add <span className='CMSMobileTextHide'>&nbsp;New Card</span>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Container className="dndContainer">
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="cards">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {cardData.sort((a, b) => a.orderNumber - b.orderNumber).map((data, index) => (
                        <Draggable key={data.name + index} draggableId={data.name + index} index={index}>
                          {(provided) => (
                            <Box
                              className="dndList"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Tooltip title="Drag to re-order">
                                <IconButton
                                  className='CMSMobileDragIcon'
                                  edge="start"
                                  aria-label="drag"
                                  disableRipple
                                  {...provided.dragHandleProps}
                                >
                                  <DragIndicatorIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Turn On/Off">
                                <Switch
                                  className='CMSMobileActiveSwitch'
                                  color="success"
                                  defaultChecked={data.isActive}
                                  onClick={() => handleActivateButton(data.id, data.isActive)}
                                />
                              </Tooltip>
                              <img 
                                style={{
                                  width: '60px',
                                  height: '80px',
                                  flexGrow: 0,
                                  flexShrink: 0,
                                  margin: '0 10px 0 20px'
                                }} 
                                className='CMSMobileListImageDisplay'
                                src={data.image} 
                                alt="" 
                              />
                              <span className='CMSInviShadow'></span>
                              <Typography sx={{ mr: 'auto' }} variant="body1" className='CMSMobileTitleList'>
                                {data.name}
                              </Typography>
                              <Tooltip title="Edit">
                                <IconButton
                                  sx={{ ml: 2 }}
                                  edge="end"
                                  aria-label="edit"
                                  className='CMSMobileEditBtn'
                                  onClick={() => handleEditCardButton(data.id)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  sx={{ ml: 2, mr: 1 }}
                                  edge="end"
                                  aria-label="delete"
                                  className='CMSMobileDeleteBtn'
                                  onClick={() => openDeleteDialog(data.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Container>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={deleteDialog}
        onClose={closeDeleteDialog}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete News"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to delete &ldquo;<strong style={{color: '#33bfff'}}>{deleteName}</strong>&rdquo;?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} style={{color: cancelColor}}>
            Cancel
          </Button>
          <Button onClick={() => handleDeleteCard(selectedObject)} style={{color: deleteColor}}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth='md'
        fullWidth={true}
        fullScreen={fullScreen}
        open={createEditDialog}
        onClose={closeCreateEditDialog}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box sx={{ width: '100%' }} style={{paddingTop: '105px'}}>
            <Stepper activeStep={activeStep} alternativeLabel style={{backgroundColor: 'whitesmoke', padding: '20px', position: 'absolute', top: 0, left: 0, right: 0}}>
              {steps.map((label) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  onClick={closeCreateEditDialog}
                  sx={{ mr: 1 }}
                  disabled={disabled}
                >
                  Cancel
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  color="inherit"
                  disabled={disabled || activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Button 
                  onClick={activeStep === steps.length - 1 ? handleDone : activeStep === 0 ? (!(selectedObject === null) ? handleEditCard : handleCreateCard) : handleNext}
                  disabled={
                    (disabled || (activeStep === 0 && !(object.name && object.year && object.author && object.image))) 
                  }
                >
                    {activeStep === steps.length - 1 ? 'Save' : 'Next' }
                </Button>
              </Box>
            </React.Fragment>
          </Box>
        </DialogContent>
      </Dialog>    
      {loading ? <Loading loading={loading} setLoading={setLoading} /> : ''}   
    </ThemeProvider>
  );
}

export default CMSSubPageItem