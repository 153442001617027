import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container } from '@mui/system';
import {Grid, Button, Box} from '@mui/material/';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { styled } from '@mui/material/styles';
import PermMediaIcon from '@mui/icons-material/PermMedia';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const theme = createTheme({
    palette: {
        primary: {
        main: '#33bfff',
        },
        success: {
        main: '#0ec917',
        },
        secondary: {
        main: '#90a4ae',
        },
    },
});

const AboutTabsStep2 = ({object, setObject, setFile}) => {
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setObject({...object, img: reader.result});
                setFile(file)
            };
            reader.readAsDataURL(file);
        }
    };
    return (
        <ThemeProvider theme={theme}>
            <Container 
                style={{
                    width: '100%',
                    height: '550px',
                    padding: '20px',
                    backgroundColor: 'whitesmoke',
                    borderRadius: '8px',
                    border: '1px dashed #CCCCCC',
                    overflowY: 'auto'
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign="right">
                        <Box 
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: '1px solid #cccccc',
                                padding: '15px',
                                borderRadius: '4px',
                                backgroundColor: 'white'
                            }}
                        >
                            <Box style={{marginBottom: '10px'}}>
                                {object.img ? (
                                <img
                                    src={object.img}
                                    alt="Selected"
                                    style={{
                                        width: '100%',
                                        height: '425px',
                                    }}
                                />
                                ) : (
                                <Box
                                    style={{
                                        border: '1px dashed #cccccc',
                                        width: '100%',
                                        height: '425px',
                                        borderRadius: '2px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <PhotoSizeSelectActualIcon style={{color: '#cccccc', fontSize: '50px'}} />
                                </Box>
                                )}
                            </Box>
                            <Box 
                                style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                }}
                            >
                                <Button
                                    component="label"
                                    role={undefined}
                                    variant="contained"
                                    tabIndex={-1}
                                    size='medium'
                                    startIcon={<PermMediaIcon />}
                                    color='secondary'
                                    style={{color: 'white', textTransform: 'none'}}
                                >
                                Browse Cover Image
                                <VisuallyHiddenInput type="file" accept="image/*" onChange={handleImageChange} />
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    )
}

export default AboutTabsStep2