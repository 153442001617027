import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Container } from '@mui/system';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import KeyIcon from '@mui/icons-material/Key';
import ReplyIcon from '@mui/icons-material/Reply';
import Button from '@mui/material/Button';
import { Typography, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { resetPassword } from '../api/globalCMSServices';

import Loading from '../components/spinner/Loading';

const BackBtn = styled(Button)({
  textTransform: 'none',
  borderRadius: '50px',
  border: '1px solid #33bfff',
  color: '#33bfff'
});

const style = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-Repeat',
  backgroundSize: 'cover'
}

const ForgotPassword = () => {
  const navigate =  useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const [successResponse, setSuccessResponse] = useState(null)
  const [errorResponse, setErrorResponse] = useState(null)
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({
    'token': queryParams.get('token').replace(/ /g, '+'),
    'email': queryParams.get('email'),
    'newPassword': '',
    'confirmPassword': ''
  });

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorResponse(null);
    setSuccessResponse(null);
    try {
      await  resetPassword(password);
      setSuccessResponse('Your password has been successfully updated. Please use your new password for your next login session.');
    } catch (error) {
      if (error.response) {
        const errorData = error.response.data;
        if (errorData.errors) {
          const formattedErrors = Object.entries(errorData.errors)
            .map(([key, messages]) => `${messages.join(', ')}`)
            .join('\n');
          setErrorResponse(formattedErrors);
        } else {
          setErrorResponse(errorData);
        }
      } else {
        setErrorResponse('An unexpected error occurred.');
      }
    }
    setLoading(false);
  };

  return (
    <Container style={style} maxWidth={false}>
      <Stack 
        style={{height: '100%'}}
        spacing={{ xs: 1, sm: 2 }}
        direction="column"
        useFlexGap
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        <img style={{height: '170px', width: '170px', filter: 'grayscale(1)'}} src='https://iwrmdataportal.unepdhi.org/static/media/un-environment.8672d8916119c4e56f0e8d3631870ede.svg' alt=''  />
        
        <Typography variant="h1" style={{ fontSize: '24px'}}>Create New Password</Typography>
        <Card sx={{ minWidth: '800px', background: 'rgba(255, 255, 255, 0.60)', backdropFilter: 'blur(7px)', padding: '30px 30px 50px' }}>
          <form onSubmit={handleResetPassword}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '10px 0 20px' }}>
              <KeyIcon sx={{ color: 'action.active', mr: 2, my: 0.5, fontSize: '40px' }} />
              <TextField
                id="newPassword"
                fullWidth
                label="Please input your new password"
                type='password'
                variant="outlined"
                required
                disabled={successResponse !== null}
                onChange={(e) => setPassword({...password, newPassword: e.target.value})}/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '10px 0 20px' }}>
              <KeyIcon sx={{ color: 'action.active', mr: 2, my: 0.5, fontSize: '40px' }} />
              <TextField
                id="confirmNewPassword"
                fullWidth
                label="Please confirm your new password"
                type='password'
                variant="outlined"
                required
                disabled={successResponse !== null}
                onChange={(e) => setPassword({...password, confirmPassword: e.target.value})}/>
            </Box>
            <Button type="submit" variant="contained" disabled={successResponse !== null} fullWidth size="large" style={{margin: '20px 0'}}>Submit</Button>
          </form>
        </Card>
        {successResponse ? <Alert variant="filled" severity="success">{successResponse}</Alert> : ''}
        {errorResponse ? <Alert variant="filled" severity="error">{errorResponse}</Alert> : ''}
        <BackBtn
          component="label"
          role={undefined}
          variant="outlined"
          size='small'
          startIcon={<ReplyIcon />}
          onClick={() => {
            navigate('/login')
          }}
        >
          Back to login
        </BackBtn>
      </Stack>
      {loading ? <Loading loading={loading} setLoading={setLoading} /> : ''}
    </Container>
  )
}

export default ForgotPassword