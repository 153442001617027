import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Container } from '@mui/system';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import TabIcon from '@mui/icons-material/Tab';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled, Box, Typography, TextField, Tabs, Tab, Alert, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slide from '@mui/material/Slide';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { grey, red } from '@mui/material/colors';
import Loading from '../components/spinner/Loading';
import { loadReportType, loadReportTypeId, createReportType, editReportType, deleteReportType, reorderReportType, updateReportTypeStatus } from '../api/reportTypeServices';

const emptyObject = {
  id: '',
  typeName: '',
  isActive: false,
  orderNumber: '',
  fileType: '',
  GA_Action: '',
  GA_Category: '',
}

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #F0f0f0',
  marginBottom: '23px'
});

const AntTab = styled(Tab)({
  textTransform: 'none'
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#33bfff',
    },
    success: {
      main: '#0ec917',
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CMSReportType = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [cardData, setCardData] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [createEditDialog, setCreateEditDialog] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedObject, setSelectedObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteName, setDeleteName] = useState(null);
  const [object, setObject] = useState(emptyObject);
  const cancelColor = grey[500];
  const deleteColor = red[500];

  const fetchReportType = async () => {
    setLoading(true);
    try {
      const reportType = await loadReportType();
      setCardData(reportType);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchReportType();
  }, []);

  const handleOnDragEnd = async (result) => {
    setLoading(true);
    const { destination, source } = result;
    if (!destination) return;
    const items = Array.from(cardData);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    const data = items.map((item, index) => ({
      ...item,
      id: item.id,
      orderNumber: index + 1,
    }));
    const newOrder = data.map((item) => ({
      id: item.id,
      orderNumber: item.orderNumber,
    }));
    try {
      await  reorderReportType(newOrder);
      setCardData(data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleActivateButton = async (id, current) => {
    setLoading(true);
    const newStatus = !current;
    const status = {
      'id': id,
      'isActive': newStatus
    }
    try {
      await  updateReportTypeStatus(status);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const openDeleteDialog = (id) => {
    const item = cardData.find(obj => obj.id === id);
    setDeleteName(item.typeName)
    setSelectedObject(id)
    setDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const closeCreateEditDialog = () => {
    setCreateEditDialog(false);
  };

  const handleAddButton = () => {
    setCreateEditDialog(true);
    setObject(emptyObject)
    setSelectedObject(null)
  };

  const handleEditButton = async(id) => {
    setLoading(true);
    setObject(emptyObject);
    setCreateEditDialog(true);
    setSelectedObject(id)
    try {
      const reportTypeId = await loadReportTypeId(id);
      setObject(reportTypeId);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleCreate = async (e) => {
    setLoading(true);
    setCreateEditDialog(false);
    e.preventDefault();
    try {
      await createReportType(object);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleEdit = async (e) => {
    setLoading(true);
    setCreateEditDialog(false);
    e.preventDefault();
    try {
      await editReportType(object);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error uploading:', error);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    setDeleteDialog(false);
    try {
      await  deleteReportType(id);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      console.error('Error Deleting:', error);
    }
    const updatedItems = cardData.filter(item => item.id !== id);
    setCardData(updatedItems)
    setLoading(false);
  };

  const goToResourceLibrary = () => {
    navigate("/cms/resource-library");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTypeNameChange = (event) => {
    setObject({...object, typeName: event.target.value});
  };

  const handleTypeChange = (event) => {
    setObject({...object, GA_Action: event.target.value});
  };

  const handleFormatChange = (event) => {
    setObject({...object, fileType: event.target.value});
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Box>
          <AntTabs 
            value={value}
            onChange={handleChange}
            allowScrollButtonsMobile
          >
            <AntTab 
              label='Sub-menu'
              icon={<TabIcon />}
              iconPosition="start"
              onClick={goToResourceLibrary} 
            />
            <AntTab 
              label='Report Type'
              icon={<SettingsIcon />}
              iconPosition="start"
            />
          </AntTabs>
        </Box>
        <Grid container spacing={2} >
          <Grid item xs={12} style={{
            display: 'flex',
            flexDirection: 'row'
          }}>
            <Typography variant="h6" xs={{ mr: 'auto' }}>Report Type</Typography>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ color: 'white', borderRadius: '50px' }}
              startIcon={<AddIcon />}
              onClick={handleAddButton}
            >
              Add <span className='CMSMobileTextHide'>&nbsp;New Type</span>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Container className="dndContainer">
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="cards">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {cardData.sort((a, b) => a.orderNumber - b.orderNumber).map((data, index) => (
                        <Draggable key={data.typeName + index} draggableId={data.typeName + index} index={index}>
                          {(provided) => (
                            <Box
                              className="dndList noImage"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Tooltip title="Drag to re-order">
                                <IconButton
                                  edge="start"
                                  aria-label="drag"
                                  disableRipple
                                  className='CMSMobileDragIcon'
                                  {...provided.dragHandleProps}
                                >
                                  <DragIndicatorIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Turn On/Off">
                                <Switch
                                  className='CMSMobileActiveSwitch'
                                  color="success"
                                  defaultChecked={data.isActive}
                                  onClick={() => handleActivateButton(data.id, data.isActive)}
                                />
                              </Tooltip>
                              <Typography sx={{ mr: 'auto' }} variant="body1" className='CMSMobileTitleList noImage'>
                                {data.typeName}
                              </Typography>
                              <span className='CMSInviShadow'></span>
                              <Tooltip title="Edit">
                                <IconButton
                                  sx={{ ml: 2 }}
                                  edge="end"
                                  aria-label="edit"
                                  className='CMSMobileEditBtn'
                                  onClick={() => handleEditButton(data.id)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  sx={{ ml: 2, mr: 1 }}
                                  edge="end"
                                  aria-label="delete"
                                  className='CMSMobileDeleteBtn'
                                  onClick={() => openDeleteDialog(data.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Container>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={deleteDialog}
        onClose={closeDeleteDialog}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete News"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are you sure you want to delete &ldquo;<strong style={{color: '#33bfff'}}>{deleteName}</strong>&rdquo;?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} style={{color: cancelColor}}>
            Cancel
          </Button>
          <Button onClick={() => handleDelete(selectedObject)} style={{color: deleteColor}}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth='md'
        fullWidth={true}
        fullScreen={fullScreen}
        open={createEditDialog}
        onClose={closeCreateEditDialog}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Alert severity="info"><b>Add New Action</b><br /><span>Customize Event Name, Event Category, Event Label are only available for Resource Library Page</span></Alert>
            <React.Fragment>
              <Container 
                style={{
                    width: '100%',
                    height: '400px',
                    padding: '20px',
                    marginTop: '10px',
                    backgroundColor: 'whitesmoke',
                    borderRadius: '8px',
                    border: '1px dashed #CCCCCC',
                    overflowY: 'auto'
                }}
              >
                <Box
                  style={{
                    border: '1px dashed #CCCCCC',
                    borderRadius: '5px',
                    backgroundColor: '#FFFFFF',
                    marginBottom: '20px'
                  }}
                >
                  <ul>
                    <li><strong style={{width: '200px', display: 'block', float: 'left'}}>Download: </strong>upload a document</li>
                    <li><strong style={{width: '200px', display: 'block', float: 'left'}}>Video_click: </strong>url link</li>
                    <li><strong style={{width: '200px', display: 'block', float: 'left'}}>External_link: </strong>url link</li>
                  </ul>
                </Box>
                <Grid container spacing={2} className='jodit360'>
                    <Grid item xs={6} style={{textAlign: 'center'}}>
                      <TextField
                        id="reportType"
                        label="New Report Type"
                        type='text'
                        size='small'
                        style={{marginBottom: '20px', backgroundColor: 'white', width: '220px'}}
                        value={object.typeName}
                        onChange={handleTypeNameChange}
                      />
                      <br />
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">File Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={object.fileType}
                          label="File Type"
                          onChange={handleFormatChange}
                          size='small'
                          style={{backgroundColor: 'white', textAlign: 'left', width: '220px'}}
                        >
                          <MenuItem value='doc/pdf'>doc/pdf</MenuItem>
                          <MenuItem value='ppt'>ppt</MenuItem>
                          <MenuItem value='url/link'>url/link</MenuItem>
                          <MenuItem value='zip'>zip</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: 'center'}}>
                      <TextField
                        id="eventCategory"
                        label="GA Category"
                        type='text'
                        size='small'
                        disabled
                        style={{marginBottom: '15px', backgroundColor: 'white', width: '220px'}}
                        value='resource_library'
                      />
                      <br />
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">GA Action</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={object.GA_Action}
                          label="File Type"
                          onChange={handleTypeChange}
                          size='small'
                          style={{backgroundColor: 'white', textAlign: 'left', width: '220px'}}
                        >
                          <MenuItem value='download'>Download</MenuItem>
                          <MenuItem value='video_click'>Video click</MenuItem>
                          <MenuItem value='external_link'>External link</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                </Grid>
              </Container>    
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  onClick={closeCreateEditDialog}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button 
                  onClick={!(selectedObject === null) ? handleEdit : handleCreate}
                  disabled={
                    (!(object.typeName && object.fileType && object.GA_Action))
                  }
                >
                  Save
                </Button>
              </Box>
            </React.Fragment>
          </Box>
        </DialogContent>
      </Dialog>    
      {loading ? <Loading loading={loading} setLoading={setLoading} /> : ''}    
    </ThemeProvider>
  );
}

export default CMSReportType