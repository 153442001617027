import React from 'react';
import { Container, Grid, TextField, Button, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const ButtonList = ({newsObject, setNewsObject}) => {

  const handleButtonChange = (index, field, value) => {
    const updatedButtons = newsObject.button.map((btn, i) => 
      i === index ? { ...btn, [field]: value } : btn
    );
    setNewsObject({ ...newsObject, button: updatedButtons });
  };

  const addButton = () => {
    setNewsObject({
      ...newsObject,
      button: [
        ...newsObject.button,
        { link: '', buttonText: '' }
      ]
    });
  };

  const removeButton = (index) => {
    const updatedButtons = newsObject.button.filter((_, i) => i !== index);
    setNewsObject({ ...newsObject, button: updatedButtons });
  };

  return (
    <Container
      style={{
        width: '100%',
        height: '350px',
        padding: '20px',
        backgroundColor: 'whitesmoke',
        borderRadius: '8px',
        border: '1px dashed #CCCCCC',
        overflowY: 'auto'
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
            <Typography variant='h6'>Buttons</Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
            <Button aria-label="add" disabled={!(newsObject.button[newsObject.button.length - 1]?.link && newsObject.button[newsObject.button.length - 1]?.buttonText)} onClick={addButton} startIcon={<AddIcon />}>
                Add Button
            </Button>
        </Grid>

        {newsObject.button.map((btn, index) => (
          <React.Fragment key={index}>
            <Grid item xs={10} sm={7}>
              <TextField
                required
                fullWidth
                label={`Link ${index + 1}`}
                value={btn.link}
                onChange={(e) => handleButtonChange(index, 'link', e.target.value)}
                type='text'
                size='small'
                style={{ marginBottom: '15px', backgroundColor: 'white' }}
              />
            </Grid>
            <Grid item xs={10} sm={4}>
              <TextField
                required
                fullWidth
                label={`Button Text ${index + 1}`}
                value={btn.buttonText}
                onChange={(e) => handleButtonChange(index, 'buttonText', e.target.value)}
                type='text'
                size='small'
                style={{ marginBottom: '15px', backgroundColor: 'white' }}
              />
            </Grid>
              <Grid item xs={1}>
                <IconButton aria-label="delete" disabled={newsObject.button.length === 1} onClick={() => removeButton(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Container>
  );
};

export default ButtonList;
