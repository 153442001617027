import React, { useState, useEffect, Fragment, useCallback } from 'react'
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPublicationData } from '../../hooks/usePublicationAction';
import {
  // GET_PUBLICATION_REPORTS_PARAMETER_BY_TYPE,
  // GET_ACTIVE_PUBLICATION_TYPES
} from '../../api/IWRMServices'
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import {
  Link,
  Stack,
  Box,
  Typography,
  Card,
  CardMedia,
  Tabs,
  Tab
} from '@mui/material';
import Search from '../../components/search/Search';
import { GAPush } from '../../utils/DownloadAnalytic'
import { useAppContext } from '../../context/AppContext';
import Spinner from '../../components/spinner/Spinner';
import { loadSubPage } from '../../api/subPageServices';
import { loadAllReportCard } from '../../api/reportListServices';

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontSize: 12
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Publication = () => {
  const {
    state: { loading }, action: { setLoading }
  } = useAppContext();
  const dispatch = useDispatch();
  const storedData = useSelector(state => state.publication);
  const [value, setValue] = useState(0);
  const [reportType, setReportType] = useState('global-reports');
  const [cardData, setCardData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTabs, setActiveTabs] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await loadSubPage();
      if (result) {
        setActiveTabs(result);
      }
    };
    fetchData();
  }, []);

  const newPublicationsData = useCallback(async (type) => {
    setLoading(true);
    try {
      if (storedData && storedData[type]) {
        setCardData(storedData[type]);
      } else {
        const data = await loadAllReportCard(type);
        dispatch(setPublicationData(type, data));
        setCardData(data);
      }
    } catch (error) {
      console.error('Error fetching publication reports:', error);
    }
    setLoading(false);
  }, [setLoading, dispatch, storedData]);
  

  useEffect(() => {
    const path = location.pathname;
    function handlePathChange() {
      const activeTab = activeTabs.find(tab => '/publications/'+tab.url === path);
  
      if (activeTab) {
        setReportType(activeTab.name);
        newPublicationsData(activeTab.url);
      }
    }
    handlePathChange();
  }, [location.pathname, activeTabs, newPublicationsData]);

  const filteredTabs = activeTabs
  .filter(tab => tab.isActive !== false)
  .sort((a, b) => a.orderNumber - b.orderNumber);

  return (
    <Fragment>
      <Helmet>
        <title>IWRM Data Portal - Resource Library</title>
        <meta name="description" content="This page is include global, regional, result, and thematic report" />
        <meta name="keywords" content="IWRM, SDG 6, SDG 6.5.1, water management, sustainable development, publication & methodology IWRM, publication & methodology SDG 6, publication & methodology SDG 6.5.1, Resource Library IWRM, Resource Library SDG 6, Resource Library SDG 6.5.1" />
      </Helmet>
      <div className="main-container" style={{ padding: '0px 20px' }}>
        <div className="one-column">
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
            <Tabs value={value} onChange={handleChange} aria-label="publication-type-tabs">
              {
                filteredTabs.map((item, index) =>  (
                  <CustomTab
                    label={item.name}
                    key={`${item.Id}_${index}`}
                    onClick={() => {
                      setReportType(item.url)
                      navigate('/publications/'+item.url)
                      GAPush(item.GA_Action, item.GA_Category, item.GA_Label)
                    }}
                    {...a11yProps(index)}
                  />
                ))
              }
            </Tabs>
          </Box>
          <Search
            title="Search for a publications"
            onSearch={(e) => setSearchQuery(e.target.value.toLowerCase())}
          />
          {loading ? (
            <Spinner />
          ) : (
            filteredTabs.map((item, index) => { 
              const uniqueKey = `${item.url}-${index}`;
              return (
              <TabPanel value={value} index={index} key={uniqueKey}>
                <Stack
                  flexDirection={'row'}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}>
                  {cardData.length !== 0 && cardData.every(card => card.isActive)  ? 
                    (
                      cardData.filter(item => 
                        (item.name?.toLowerCase().includes(searchQuery) || '') ||
                        (item.year?.toString().includes(searchQuery) || '') ||
                        (item.author?.toLowerCase().includes(searchQuery) || '')
                      )
                      .filter(item => item.isActive !== false )
                      .sort((a, b) => a.orderNumber - b.orderNumber)
                      .map(object => {
                        const languageOrder = ["English", "French", "Spanish", "Arabic", "Chinese", "Portuguese", "Russian"];
                        const predefinedOrder = [
                            "Full report",
                            "Visual summary",
                            "Executive summary",
                            "Social media card",
                            "PowerPoint slide",
                            "Watch the video"
                        ];
                        const reports = object.listPublicationsSubReportsParameters;

                        const sortByLanguage = (a, b) => {
                            const indexA = languageOrder.indexOf(a.language);
                            const indexB = languageOrder.indexOf(b.language);
                            const adjustedIndexA = indexA !== -1 ? indexA : Infinity;
                            const adjustedIndexB = indexB !== -1 ? indexB : Infinity;
                            return adjustedIndexA - adjustedIndexB;
                        };
                        
                        const uniqueTypes = Array.from(new Set(reports.map(report => report.typeName)));
                        const orderedTypes = [
                            ...predefinedOrder,
                            ...uniqueTypes.filter(type => !predefinedOrder.includes(type))
                        ];
                        return (
                          <Card
                            key={object.name}
                            sx={{
                              display: 'flex',
                              width: '520px',
                              marginRight: '20px',
                              marginBottom: '20px'
                            }}
                            
                          >
                            <Box>
                              <CardMedia
                                component="img"
                                sx={{ width: '220px', height: '100%' }}
                                image={object.image}
                                alt={object.name}
                              />
                            </Box>
                            <Box sx={{ flexDirection: 'column', padding: 1 }}>
                              {
                                reportType !== 'Result' && (
                                  <Box>
                                    <Typography variant="subtitle1" style={{ color: '#09334B', fontSize: 13 }}>
                                      {object.name}
                                    </Typography>
                                    <Stack sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                                      <Typography variant="subtitle1" color="black" sx={{ flex: 1, color: '#09334B', fontSize: 13 }}>
                                        <b>Year</b> : {object.year}
                                      </Typography>
                                      <Typography variant="subtitle1" color="black" sx={{ flex: 1, color: '#09334B', fontSize: 13 }}>
                                        <b>Author</b> : {object.author}
                                      </Typography>
                                    </Stack>
                                  </Box>
                                )
                              }
                              <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 1 }}>
                                <Typography variant="subtitle1" color="black" sx={{ flex: 1, color: '#09334B', fontSize: 12 }}>
                                  <b>Download</b> :
                                </Typography>
                                {
                                  reportType !== 'Result' ? (
                                    <>
                                      {orderedTypes.map(type => {
                                        const filteredReports = reports
                                            .filter(report => report.typeName === type)
                                            .sort(sortByLanguage);
                                        if (filteredReports.length === 0) return null;

                                        return (
                                          <Stack
                                              flexDirection="row"
                                              flexWrap="wrap"
                                              key={type}
                                          >
                                              <Typography sx={{ fontSize: 13, color: "black", marginRight: 1 }}>
                                                  {type} :
                                              </Typography>
                                              {filteredReports.map(item => (
                                                  <Link
                                                      href={item.link || item.linkText}
                                                      target="_blank"
                                                      key={item.subId}
                                                      sx={{
                                                          textDecoration: "none",
                                                          color: "#00aeef",
                                                          fontSize: 13,
                                                          marginRight: 1,
                                                          width: "fit-content"
                                                      }}
                                                      onClick={() => {
                                                        GAPush(item.GA_Action, item.GA_Category, item.GA_Label)}
                                                      }
                                                  >
                                                      {item.label}
                                                  </Link>
                                              ))}
                                          </Stack>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    object.listPublicationsSubReportsParameters?.map(item => (
                                      <>
                                        <span style={{fontSize: '13px'}}>{`${item.label}`}</span>
                                        <Link
                                          href={item.linkText}
                                          target="_blank"
                                          key={item.Name}
                                          sx={{ textDecoration: 'none', color: '#00aeef', marginRight: 1, fontSize: 13 }}
                                          onClick={() => {
                                            GAPush('download', 'resource_library', `all_country_dataset_en_library ${item.Year}`)}
                                          }
                                        >
                                          {`${item.typeName} (Excel)`}
                                        </Link>
                                      </>
                                    ))
                                  )
                                }
                              </Box>
                            </Box>
                          </Card>
                        )
                      })
                    ) : (
                      <div style={{
                        padding: '100px 0',
                        border: '2px dashed #e9e9e9',
                        width: '100%',
                        background: '#F9F9F9',
                        borderRadius: '10px'
                      }}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                          filter: 'grayscale(1)',
                          opacity: 0.25
                        }}>
                            <img style={{
                              height: '100px',
                              width: '100px',
                            }} src='https://iwrmdataportal.unepdhi.org/static/media/clean-water-and-sanitation.9729f2ea138cf1e5ccb9a566e90f2993.svg' alt=''  />
                            <img style={{
                              height: '170px',
                              width: '170px',
                            }} src='https://iwrmdataportal.unepdhi.org/static/media/un-environment.8672d8916119c4e56f0e8d3631870ede.svg' alt=''  />
                        </div>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                          fontSize: '18px', 
                          color: '#ababab',
                          fontWeight: 400
                        }}>
                            Coming soon
                        </div>
                      </div>
                    ) 
                  }
                  {reportType === 'Extra resources' && (
                    <Card
                      sx={{
                        display: 'flex',
                        width: '500px',
                        height: '300px',
                        marginRight: '20px',
                        marginBottom: '20px'
                      }}
                    >
                      <Box>
                        <CardMedia
                          component="img"
                          sx={{ width: '220px', height: '100%' }}
                          image={'https://iwrmdataportal.unepdhi.org/static/media/news4.a9bd93c66ac19c462056.png'}
                          alt={''}
                        />
                      </Box>
                      <Box sx={{ flexDirection: 'column', padding: 1 }}>
                        <Box>
                          <Typography variant="subtitle1" style={{ color: '#09334B', fontSize: 12 }}>
                          Learn, explore and connect on designing and implementing Integrated Water Resources Management (IWRM) action towards a water secure world.
                          </Typography><br />
                          <Typography variant="subtitle1" style={{ color: '#09334B', fontSize: 12, fontWeight: 800 }}>
                          Access the website
                          </Typography>
                          <Link
                              href='https://iwrmactionhub.org/'
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{ textDecoration: 'none', color: '#00aeef', marginRight: 1 }}
                              onClick={() => {
                                GAPush('external_link', 'resource_library', 'extra_resources_IWRM_action_hub')}
                              }
                            >
                              Here
                          </Link>
                        </Box>
                      </Box>
                    </Card>
                  ) }
                </Stack>
              </TabPanel>
            )})
          )
          }
        </div>
      </div>
    </Fragment>
  )
}

export default Publication