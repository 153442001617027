import globalVar from '../global-constanta';

export const GetSDG651Scores = (countryAnswer, latestSections, needSorting) => {
  const barChartData = { x: [], y: [], colors: [] };
  let barChartDataTemp = [];

  if (countryAnswer.length > 0) {
    // eslint-disable-next-line array-callback-return
    countryAnswer?.map(item => {
      if (item.Question) {
        if (!item.Question.QuestionMainGroup) {
          const sections = latestSections?.filter(
            c =>
              c.QuestionNumber.trim() ===
              item.Question.QuestionNumber.trim().split('.')[0],
          )[0];
          if (sections) {
            const sectionName = sections.QuestionText.split(':')[0];
            if (
              barChartDataTemp?.filter(
                c => c.name.toString().trim() === sectionName.trim(),
              ).length <= 0
            ) {
              const xColor = globalVar.SDG_LEGEND.filter(
                c => item.ActualAnswer >= c.min && item.ActualAnswer <= c.max,
              )[0];

              barChartDataTemp.push({
                id: parseInt(item.Question.QuestionNumber.split(' ')[1], 10),
                name: `${sectionName} `,
                actualAnswer: item.ActualAnswer,
                color: xColor.color,
              });
            }
          }
        }
      }
    });
    if (needSorting) {
      barChartDataTemp = barChartDataTemp.sort((a, b) => b.id - a.id);
    }

    const lastRecord = countryAnswer[countryAnswer.length - 1];
    const xColor = globalVar.SDG_LEGEND.filter(
      c => lastRecord.ActualAnswer >= c.min && lastRecord.ActualAnswer <= c.max,
    )[0];

    barChartDataTemp.push({
      id: parseInt(barChartDataTemp.length + 1, 10),
      name: `<b>${lastRecord.Question.QuestionNumber}</b>`,
      actualAnswer: lastRecord.ActualAnswer,
      color: xColor.color,
    });
    
    // eslint-disable-next-line array-callback-return
    barChartDataTemp.map(item => {
      barChartData.x.push(item.actualAnswer);
      barChartData.y.push(item.name);
      barChartData.colors.push(item.color);
    });

    return barChartData;

  }
}

export const hexToRGB = (hex) => {
  // Remove the "#" character, if it exists
  hex = hex.replace(/^#/, '');

  // Parse the hex string as a hexadecimal number
  const num = parseInt(hex, 16);

  // Extract the individual red, green, and blue components
  const red = (num >> 16) & 255;
  const green = (num >> 8) & 255;
  const blue = num & 255;

  return [red, green, blue];
}

export const removeAfterParenthesis = (str) => {
  const index = str.indexOf("(");
  if (index !== -1) {
    return str.substring(0, index).trim();
  }
  return str;
}

export const getBounds = (geoJsonFeature) => {
  let minLng = Infinity, minLat = Infinity, maxLng = -Infinity, maxLat = -Infinity;

  const processCoordinates = (coordinates) => {
    for (let i = 0; i < coordinates.length; i++) {
      const coord = coordinates[i];
      if (Array.isArray(coord[0])) {
        processCoordinates(coord); // used for Polygon/MultiPolygon
      } else {
        minLng = Math.min(minLng, coord[0]);
        minLat = Math.min(minLat, coord[1]);
        maxLng = Math.max(maxLng, coord[0]);
        maxLat = Math.max(maxLat, coord[1]);
      }
    }
  };

  processCoordinates(geoJsonFeature.geoJsonShape.coordinates);
  return [[minLng, minLat], [maxLng, maxLat]];
};

export const getCombinedBounds = (geoJsonFeatures) => {
  let minLng = Infinity, minLat = Infinity, maxLng = -Infinity, maxLat = -Infinity;

  const processCoordinates = (coordinates) => {
    for (let i = 0; i < coordinates.length; i++) {
      const coord = coordinates[i];
      if (Array.isArray(coord[0])) {
        processCoordinates(coord);
      } else {
        minLng = Math.min(minLng, coord[0]);
        minLat = Math.min(minLat, coord[1]);
        maxLng = Math.max(maxLng, coord[0]);
        maxLat = Math.max(maxLat, coord[1]);
      }
    }
  };

  geoJsonFeatures.forEach(feature => {
    processCoordinates(feature.geoJsonShape.coordinates);
  });

  return [[minLng, minLat], [maxLng, maxLat]];
};

export const calculateCentroid = (geoJsonFeature) => {
  let sumLng = 0, sumLat = 0, numPoints = 0;

  const processCoordinates = (coordinates) => {
    coordinates.forEach(coord => {
      if (Array.isArray(coord[0])) {
        processCoordinates(coord); // used for Polygon/MultiPolygon
      } else {
        sumLng += coord[0];
        sumLat += coord[1];
        numPoints += 1;
      }
    });
  };

  processCoordinates(geoJsonFeature.geoJsonShape.coordinates);

  return [sumLng / numPoints, sumLat / numPoints];
};

export const getInitialYearValue = (year) => {
  switch (year) {
    case 2017:
      return 0;
    case 2020:
      return 1;
    case 2023:
      return 2;
    default:
      return 2;
  }
};

export const getInitialMapTypeValue = (type) => {
  switch (type) {
    case 'countries':
      return 0;
    case 'regions':
      return 1;
    default:
      return 1;
  }
};

export const getYearValue = (year, setYearValue) => {
  switch (year) {
    case 2017:
      setYearValue(0);
      break;
    case 2020:
      setYearValue(1);
      break;
    case 2023:
      setYearValue(2);
      break;
    default:
      break;
  }
}


export const urlEncode = (str) => {
  return str
    .replace(/:/g, 'colonRep')
    .replace(/\//g, 'slashRep')
    .replace(/&/g, 'ampRep');
}

export const specificColor = (status) => {
  switch (status) {
    case 'Focal point confirmation pending':
      return '#fcae91';
    case 'Reporting in progress':
      return '#E0F3DB';
    case '2023 Data submitted':
      return '#a1dab4';
    case '2023 Data finalized':
      return '#41b6c4';
    default:
      return '#cccccc'; // default color
  }
};

export const getGeographicArray = (geoJsonArray) => {
  const geographicArray = [];
  for (let i = 0; i < geoJsonArray.length; i++) {
      if (geographicArray.indexOf(geoJsonArray[i].geographic) === -1) {
          if (geoJsonArray[i].geographic !== null) {
              geographicArray.push(geoJsonArray[i].geographic);
          }
      }
  }
  return geographicArray;
}

export const getEconomicArray = (geoJsonArray) => {
  const economicArray = [];
  for (let i = 0; i < geoJsonArray.length; i++) {
      if (geoJsonArray[i].economic.length > 0) {
          for (let j = 0; j < geoJsonArray[i].economic.length; j++) {
              if (economicArray.indexOf(geoJsonArray[i].economic[j]) === -1) {
                  economicArray.push(geoJsonArray[i].economic[j]);
              }
          }
      }
  }
  return economicArray;
}