import apiClient from './apiClient';

export const login = async (user) => {
  try {
    const response = await apiClient.post('/Auth/login', user);
    return response.data;
  } catch (error) {
    console.error('Error uploading:', error);
    throw error;
  }
};

export const getNavigation = async () => {
  try {
    const response = await apiClient.get('/MenuBar/getAllMenuBar');
    return response.data;
  } catch (error) {
    console.error('Error uploading:', error);
    throw error;
  }
};

export const changePassword = async (submitData, uid) => {
  const updatedData = {
    ...submitData,
    userId: uid
  }
  try {
      const response = await apiClient.post('/Auth/changePassword', updatedData);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const refreshAuthToken = async (oldToken) => {
  try {
      const response = await apiClient.post('/Auth/refreshToken', oldToken);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const requestResetPassword = async (email) => {
  try {
      const response = await apiClient.post('/Auth/forgotPassword', email);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};

export const resetPassword = async (password) => {
  try {
      const response = await apiClient.post('/Auth/resetPassword', password);
      return response.data;
  } catch (error) {
      console.error('Error uploading:', error);
      throw error;
  } 
};
